import React, { useState } from "react";
import github from "../images/github.svg";
import linkedin from "../images/linkedin.svg";
import instagram from "../images/instagram.svg";
import ButtonSend from "./ButtonSend";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";

function Contact() {
  const [errors, seterrors] = useState([]);
  const [alert, setalert] = useState("");
  const [color, setcolor] = useState("");

  function validEmail(email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function sendEmail(e) {
    let error = [];
    seterrors([]);
    e.preventDefault();

    if (!e.target.name.value) {
      error = error.concat(t("contact.nomR"));
    }
    if (!e.target.email.value) {
      error = error.concat(t("contact.emailR"));
    } else if (!validEmail(e.target.email.value)) {
      error = error.concat(t("contact.emailV"));
    }
    if (!e.target.message.value) {
      error = error.concat(t("contact.messageR"));
    }
    if (!e.target.accept.checked) {
      error = error.concat(t("contact.acceptR"));
    }
    seterrors(error);
    if (error.length === 0) {
      console.log("valid");
      emailjs
        .sendForm(
          "service_58ngpqx",
          "template_wg1o7wp",
          e.target,
          "CI5HCxHWMA9d7IU-Z"
        )
        .then(
          (result) => {
            setalert(t("contact.valid"));
            setcolor("text-success");
            console.log(result.text);
          },
          (error) => {
            setalert(`Erreur lors de l'envoi : ${error.message}`); // Afficher un message d'erreur détaillé
            setcolor("text-danger");
            console.error(error); // Afficher l'erreur dans la console
          }
        );
      return true;
    } else {
      seterrors(error);
      console.log("error");
    }
  }
  const { t } = useTranslation();

  return (
    <div
      className="container-bg bg-contact d-flex align-items-center justify-content-center flex-column"
      id="contact"
    >
      <div className="text-center mb-5">
        <h2 className="contact-h2">{t("contact.intro")}</h2>
        <h3 className="contact-h3">{t("contact.intro2")}</h3>
        <div className="errors">
          {errors.length ? t("contact.error") : ""}
          <ul className="list-unstyled d-flex">
            {Object.keys(errors).map((item, index) => (
              <li key={index}>{errors[item]}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="container-contact d-flex align-items-center">
        <div className="d-flex position-relative container-rs">
          <p className="mail">contact@lenaclavier.com</p>
          <div className="d-flex flex-column container-rs-mob">
            <div className="gif"></div>
            <ul className="list-unstyled d-inline-flex justify-content-end list-rs">
              <li>
                <a href="https://github.com/l-naC">
                  <img src={github} alt="github" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/l%C3%A9na-clavier/">
                  <img src={linkedin} alt="linkedin" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/lenaclavier/">
                  <img src={instagram} alt="instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <form className="contact-form" onSubmit={sendEmail}>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Prénom"
              name="name"
            ></input>
            <label htmlFor="name">{t("contact.nom")}</label>
          </div>
          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="mail"
              placeholder="Email"
              name="email"
            ></input>
            <label htmlFor="mail">{t("contact.email")}</label>
          </div>
          <div className="form-floating">
            <textarea
              className="form-control"
              id="message"
              placeholder="Message"
              name="message"
            ></textarea>
            <label htmlFor="message" id="text-label">
              {t("contact.message")}
            </label>
          </div>
          <div className="form-checkbox">
            <input type="checkbox" id="accept" name="accept" />
            <label htmlFor="accept">{t("contact.accept")}</label>
          </div>
          <p className={`valid-message ${color}`}>{alert}</p>
          <div className="button button-send">
            <ButtonSend />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
