import React, { useState } from "react";
import open from "../images/open.svg";
import close from "../images/close.svg";
import { NavHashLink } from "react-router-hash-link";
import { Language } from "../enums/Language";
import { useTranslation } from "react-i18next";

export default function Header() {
  const [isActive, setActive] = useState("false");
  const [navbar, setNavbar] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };

  const showNavbar = () => {
    if (window.scrollY >= 300) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", showNavbar);

  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  let changeLanguage = (event) => {
    let language = event.target.value;

    switch (language) {
      case Language.EN:
        setLang(Language.EN);
        i18n.changeLanguage(Language.EN);
        break;
      case Language.FR:
      default:
        setLang(Language.FR);
        i18n.changeLanguage(Language.FR);
        break;
    }
  };
  return (
    <div
      role="navigation"
      className={`header fixed-top d-flex ${
        isActive ? "" : "bg-white h-100 overflow-hidden"
      }`}
    >
      <NavHashLink activeClassName="selected" to="/">
        <p className={`${isActive ? "" : "p-active"}`}>Léna Clavier</p>
      </NavHashLink>
      <img
        src={open}
        className={`open ${navbar ? "show" : "fade"} ${
          isActive ? "open-active" : ""
        }`}
        alt="open menu"
        onClick={handleToggle}
      />
      <ul
        className={`navigation ${
          navbar || (!isActive && !navbar) ? "show" : "fade"
        } list-unstyled ${isActive ? "navigation-close" : "navigation-active"}`}
      >
        <li>
          <NavHashLink
            smooth
            activeClassName="selected"
            activeStyle={{ fontWeight: "600" }}
            to="/#project"
          >
            All projects
          </NavHashLink>
        </li>
        <li>
          <NavHashLink
            smooth
            activeClassName="selected"
            activeStyle={{ fontWeight: "600" }}
            to="/#about"
          >
            About
          </NavHashLink>
        </li>
        <li>
          <NavHashLink
            smooth
            activeClassName="selected"
            activeStyle={{ fontWeight: "600" }}
            to="/#contact"
          >
            Contact
          </NavHashLink>
        </li>
        <li>
          <select aria-label="language" value={lang} name="language" onChange={changeLanguage}>
            <option value={Language.FR}>FR</option>
            <option value={Language.EN}>EN</option>
          </select>
        </li>
      </ul>
      <img
        src={close}
        className={`close ${
          navbar || (!isActive && !navbar) ? "show" : "fade"
        } ${isActive ? "d-none" : "d-block"}`}
        alt="close menu"
        onClick={handleToggle}
      />
    </div>
  );
}
