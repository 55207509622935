import titre from "../images/titre.svg";
import titre_mobile from "../images/titre_mobile.svg";

function Intro() {
  return (
    <div className="container-bg bg-intro">
      <div className="d-flex align-items-center justify-content-center flex-column position-relative">
        {/* <h1 className="title title-main">Hello<br/>I am<br/>Léna</h1>
        <h1 className="title title-second">Developpeuse<br/>web</h1>
        <h1 className="title title-and">&</h1>
        <h1 className="title title-third">Chef de projet<br/>digital</h1> */}
        <img src={titre} id="title" alt="Lena Clavier" />
        <img src={titre_mobile} id="title-mobile" alt="Lena Clavier" />
        <h1 className="visually-hidden">Hello I am Lena, Marketing Consultant and Web Developer</h1>
      </div>
      <p className="scroll">Scroll to explore</p>
    </div>
  );
}

export default Intro;
