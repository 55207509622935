function ButtonNext() {
  return (
    <button className="border-0 bg-transparent">
      <svg
        width="105"
        height="105"
        viewBox="0 0 105 105"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="button-svg-up"
      >
        <circle cx="52.5" cy="51.5" r="36.5" fill="transparent" />
        <path
          d="M31 51.0001C30.4 51.0001 30 51.4001 30 52.0001C30 52.6001 30.4 53.0001 31 53.0001V51.0001ZM75.7 52.7001C76.1 52.3001 76.1 51.7001 75.7 51.3001L69.3 44.9001C68.9 44.5001 68.3 44.5001 67.9 44.9001C67.5 45.3001 67.5 45.9001 67.9 46.3001L73.6 52.0001L67.9 57.7001C67.5 58.1001 67.5 58.7001 67.9 59.1001C68.3 59.5001 68.9 59.5001 69.3 59.1001L75.7 52.7001ZM31 53.0001H75V51.0001H31V53.0001Z"
          fill="#F1CBB6"
          id="btn-arrow-next"
        />
      </svg> 
      <svg
        width="105"
        height="105"
        viewBox="0 0 105 105"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="button-svg"
      >
        <path
          d="M14.8008 17.9002L15.4008 17.3002C15.5008 17.2002 15.5008 17.2002 15.6008 17.2002C15.7008 17.2002 15.7008 17.2002 15.8008 17.2002L19.6008 18.9002C19.7008 19.0002 19.9008 19.0002 20.0008 19.1002C20.1008 19.2002 20.2008 19.3002 20.4008 19.3002C20.3008 19.2002 20.3008 19.1002 20.2008 18.9002C20.1008 18.7002 20.1008 18.6002 20.0008 18.5002L18.4008 14.7002C18.4008 14.6002 18.4008 14.6002 18.4008 14.5002C18.4008 14.4002 18.4008 14.4002 18.5008 14.3002L19.1008 13.7002L21.6008 19.6002L20.9008 20.3002L14.8008 17.9002Z"
          fill="#F1CBB6"
        />
        <path
          d="M21.7996 11.4999C22.0996 11.1999 22.4996 11.0999 22.8996 10.9999C23.2996 10.8999 23.5996 10.8999 23.9996 10.8999C24.3996 10.9999 24.6996 11.0999 24.9996 11.2999C25.2996 11.4999 25.5996 11.7999 25.8996 12.1999C26.1996 12.5999 26.3996 12.9999 26.4996 13.2999C26.5996 13.5999 26.6996 13.9999 26.5996 14.3999C26.5996 14.7999 26.4996 15.0999 26.2996 15.3999C26.0996 15.6999 25.7996 15.9999 25.4996 16.2999C25.1996 16.5999 24.7996 16.6999 24.3996 16.7999C23.9996 16.8999 23.6996 16.8999 23.2996 16.8999C22.8996 16.7999 22.5996 16.6999 22.2996 16.4999C21.9996 16.2999 21.6996 15.9999 21.3996 15.5999C21.0996 15.1999 20.8996 14.8999 20.7996 14.4999C20.5996 14.0999 20.5996 13.6999 20.5996 13.3999C20.5996 12.9999 20.6996 12.6999 20.8996 12.3999C21.1996 11.9999 21.3996 11.6999 21.7996 11.4999ZM24.9996 15.6999C25.4996 15.2999 25.6996 14.8999 25.6996 14.3999C25.6996 13.8999 25.4996 13.3999 24.9996 12.7999C24.5996 12.1999 24.0996 11.8999 23.6996 11.6999C23.1996 11.5999 22.7996 11.6999 22.2996 11.9999C22.0996 12.1999 21.8996 12.3999 21.7996 12.5999C21.6996 12.7999 21.5996 13.0999 21.5996 13.3999C21.5996 13.5999 21.6996 13.8999 21.7996 14.1999C21.8996 14.4999 22.0996 14.6999 22.2996 14.9999C22.4996 15.2999 22.6996 15.4999 22.9996 15.6999C23.1996 15.8999 23.4996 15.9999 23.6996 16.0999C23.8996 16.1999 24.1996 16.1999 24.3996 16.0999C24.4996 15.9999 24.7996 15.8999 24.9996 15.6999Z"
          fill="#F1CBB6"
        />
        <path
          d="M25.8004 6.60029C25.9004 6.70029 25.9004 6.80029 25.9004 6.90029C25.9004 7.00029 25.9004 7.10029 25.9004 7.20029C25.9004 7.30029 25.8004 7.40029 25.8004 7.50029C25.7004 7.60029 25.7004 7.60029 25.6004 7.70029C25.5004 7.80029 25.4004 7.80029 25.3004 7.80029C25.2004 7.80029 25.1004 7.80029 25.0004 7.80029C24.9004 7.80029 24.8004 7.70029 24.7004 7.70029C24.6004 7.60029 24.6004 7.60029 24.5004 7.50029C24.4004 7.40029 24.4004 7.30029 24.4004 7.20029C24.4004 7.10029 24.4004 7.00029 24.4004 6.90029C24.4004 6.80029 24.5004 6.70029 24.5004 6.60029C24.6004 6.50029 24.6004 6.40029 24.7004 6.40029C24.8004 6.40029 24.9004 6.30029 25.0004 6.30029C25.1004 6.30029 25.2004 6.30029 25.3004 6.30029C25.4004 6.30029 25.5004 6.40029 25.6004 6.40029C25.6004 6.50029 25.7004 6.50029 25.8004 6.60029ZM26.5004 8.30029L29.6004 13.4003L28.8004 14.0003L25.7004 8.90029L26.5004 8.30029Z"
          fill="#F1CBB6"
        />
        <path
          d="M31.1004 12.6003L28.4004 7.30029L29.0004 7.00029C29.1004 6.90029 29.2004 6.90029 29.2004 7.00029C29.3004 7.00029 29.3004 7.10029 29.4004 7.20029L29.9004 8.00029C29.9004 7.50029 30.0004 7.10029 30.1004 6.80029C30.3004 6.50029 30.5004 6.20029 30.9004 6.00029C31.0004 5.90029 31.2004 5.90029 31.3004 5.80029C31.4004 5.80029 31.6004 5.80029 31.7004 5.80029L31.9004 6.60029C31.9004 6.70029 31.9004 6.80029 31.8004 6.80029C31.8004 6.80029 31.7004 6.80029 31.5004 6.90029C31.4004 6.90029 31.2004 7.00029 31.1004 7.10029C30.8004 7.30029 30.6004 7.50029 30.4004 7.80029C30.3004 8.10029 30.2004 8.50029 30.3004 8.90029L32.0004 12.3003L31.1004 12.6003Z"
          fill="#F1CBB6"
        />
        <path
          d="M35.5 1.2002L38.4 9.4002L37.4 9.7002L34.5 1.5002L35.5 1.2002Z"
          fill="#F1CBB6"
        />
        <path
          d="M40.7996 2.40029C41.0996 2.30029 41.4996 2.30029 41.7996 2.30029C42.0996 2.30029 42.3996 2.50029 42.6996 2.60029C42.9996 2.80029 43.1996 3.00029 43.3996 3.30029C43.5996 3.70029 43.6996 4.00029 43.7996 4.40029C43.7996 4.60029 43.7996 4.70029 43.7996 4.70029C43.7996 4.80029 43.6996 4.80029 43.5996 4.80029L39.7996 5.70029C39.8996 6.10029 39.9996 6.40029 40.1996 6.60029C40.3996 6.80029 40.4996 7.00029 40.6996 7.20029C40.8996 7.40029 41.0996 7.40029 41.3996 7.50029C41.5996 7.50029 41.8996 7.50029 42.1996 7.50029C42.4996 7.40029 42.6996 7.40029 42.7996 7.30029C42.9996 7.20029 43.0996 7.10029 43.1996 7.00029C43.2996 6.90029 43.3996 6.80029 43.4996 6.70029C43.5996 6.60029 43.5996 6.60029 43.6996 6.60029C43.7996 6.60029 43.8996 6.60029 43.8996 6.60029L44.2996 6.90029C44.1996 7.10029 44.0996 7.30029 43.8996 7.40029C43.7996 7.60029 43.5996 7.70029 43.3996 7.80029C43.1996 7.90029 42.9996 8.00029 42.7996 8.10029C42.5996 8.20029 42.3996 8.30029 42.1996 8.30029C41.7996 8.40029 41.3996 8.40029 40.9996 8.40029C40.5996 8.30029 40.2996 8.20029 39.9996 8.00029C39.6996 7.80029 39.3996 7.50029 39.1996 7.20029C38.9996 6.80029 38.7996 6.40029 38.6996 5.90029C38.5996 5.50029 38.5996 5.10029 38.5996 4.70029C38.5996 4.30029 38.6996 4.00029 38.8996 3.70029C39.0996 3.40029 39.2996 3.10029 39.5996 2.90029C39.9996 2.70029 40.3996 2.50029 40.7996 2.40029ZM40.9996 3.20029C40.4996 3.30029 40.1996 3.60029 39.8996 3.90029C39.6996 4.30029 39.5996 4.70029 39.6996 5.20029L42.7996 4.40029C42.7996 4.20029 42.6996 4.00029 42.5996 3.80029C42.4996 3.60029 42.3996 3.50029 42.1996 3.40029C41.9996 3.30029 41.8996 3.20029 41.6996 3.20029C41.4996 3.20029 41.1996 3.10029 40.9996 3.20029Z"
          fill="#F1CBB6"
        />
        <path
          d="M47.9 9.3001L47.5 1.3001H48.1C48.2 1.3001 48.3 1.4001 48.4 1.5001L48.5 2.2001C48.7 1.9001 49 1.6001 49.3 1.4001C49.6 1.2001 50 1.1001 50.4 1.1001C50.7 1.1001 51.1 1.1001 51.3 1.2001C51.6 1.3001 51.8 1.5001 52 1.7001C52.4 2.0001 52.5 2.3001 52.6 2.7001C52.8 3.0001 52.8 3.5001 52.9 4.0001C52.9 4.4001 52.9 4.8001 52.8 5.2001C52.7 5.6001 52.6 5.9001 52.3 6.2001C52.1 6.5001 51.8 6.7001 51.5 6.9001C51.2 7.1001 50.8 7.2001 50.4 7.2001C50 7.2001 49.7 7.2001 49.4 7.1001C49.1 7.0001 48.9 6.8001 48.7 6.6001L48.8 9.2001L47.9 9.3001ZM50.2 1.9001C49.9 1.9001 49.6 2.0001 49.3 2.2001C49.1 2.4001 48.8 2.6001 48.6 2.9001L48.8 5.8001C49 6.0001 49.2 6.2001 49.5 6.3001C49.7 6.4001 50 6.4001 50.3 6.4001C50.9 6.4001 51.3 6.1001 51.5 5.7001C51.8 5.3001 51.9 4.7001 51.9 4.0001C51.9 3.6001 51.8 3.3001 51.7 3.0001C51.6 2.7001 51.5 2.5001 51.4 2.3001C51.3 2.1001 51.1 2.0001 50.9 1.9001C50.6 1.9001 50.4 1.9001 50.2 1.9001Z"
          fill="#F1CBB6"
        />
        <path
          d="M54.0996 7.10019L54.4996 1.2002H55.0996C55.1996 1.2002 55.2996 1.2002 55.2996 1.3002C55.2996 1.3002 55.3996 1.4002 55.3996 1.5002V2.4002C55.5996 2.0002 55.7996 1.7002 56.1996 1.5002C56.4996 1.3002 56.8996 1.2002 57.2996 1.2002C57.4996 1.2002 57.5996 1.2002 57.6996 1.3002C57.7996 1.3002 57.8996 1.4002 58.0996 1.5002L57.8996 2.3002C57.8996 2.4002 57.7996 2.4002 57.6996 2.4002C57.5996 2.4002 57.5996 2.4002 57.4996 2.3002C57.3996 2.3002 57.1996 2.2002 56.9996 2.2002C56.5996 2.2002 56.2996 2.3002 56.0996 2.5002C55.7996 2.7002 55.5996 3.0002 55.3996 3.4002L55.1996 7.2002L54.0996 7.10019Z"
          fill="#F1CBB6"
        />
        <path
          d="M61.9996 1.90016C62.3996 2.00016 62.7996 2.10016 63.0996 2.30016C63.3996 2.50016 63.6996 2.80016 63.8996 3.10016C64.0996 3.40016 64.1996 3.80016 64.2996 4.20016C64.3996 4.60016 64.2996 5.00016 64.2996 5.50016C64.1996 6.00016 64.0996 6.40016 63.8996 6.70016C63.6996 7.00016 63.4996 7.30016 63.1996 7.50016C62.8996 7.70016 62.5996 7.90016 62.1996 7.90016C61.7996 8.00016 61.3996 8.00016 60.9996 7.90016C60.5996 7.80016 60.1996 7.70016 59.8996 7.50016C59.5996 7.30016 59.2996 7.00016 59.0996 6.70016C58.8996 6.40016 58.6996 6.00016 58.5996 5.60016C58.5996 5.30016 58.5996 4.80016 58.5996 4.40016C58.6996 3.90016 58.7996 3.50016 58.9996 3.20016C59.1996 2.90016 59.3996 2.60016 59.6996 2.40016C59.9996 2.20016 60.2996 2.00016 60.6996 2.00016C61.1996 1.80016 61.5996 1.80016 61.9996 1.90016ZM60.9996 7.10016C61.5996 7.20016 61.9996 7.10016 62.3996 6.80016C62.7996 6.50016 62.9996 6.00016 63.0996 5.30016C63.1996 4.60016 63.1996 4.00016 62.9996 3.60016C62.7996 3.20016 62.3996 2.90016 61.7996 2.80016C61.4996 2.70016 61.1996 2.70016 60.9996 2.80016C60.7996 2.90016 60.5996 2.90016 60.3996 3.00016C60.1996 3.20016 60.0996 3.40016 59.8996 3.60016C59.7996 3.90016 59.6996 4.20016 59.5996 4.50016C59.4996 4.80016 59.5996 5.20016 59.5996 5.50016C59.5996 5.80016 59.6996 6.00016 59.7996 6.30016C59.8996 6.50016 60.0996 6.70016 60.2996 6.80016C60.4996 6.90016 60.6996 7.00016 60.9996 7.10016Z"
          fill="#F1CBB6"
        />
        <path
          d="M67.5 3.3001L65.7 9.4001C65.7 9.7001 65.6 9.9001 65.4 10.0001C65.3 10.2001 65.1 10.3001 65 10.4001C64.9 10.5001 64.6 10.6001 64.4 10.6001C64.2 10.6001 63.9 10.6001 63.6 10.5001C63.5 10.5001 63.4 10.4001 63.3 10.4001C63.2 10.4001 63.1 10.3001 63 10.2001L63.2 9.7001C63.2 9.7001 63.2 9.6001 63.3 9.6001H63.4H63.5H63.6C63.9 9.7001 64.1 9.7001 64.3 9.6001C64.5 9.5001 64.6 9.3001 64.7 9.0001L66.5 3.0001L67.5 3.3001ZM68.3 1.6001C68.3 1.7001 68.2 1.8001 68.2 1.9001C68.1 1.9001 68 2.0001 67.9 2.0001C67.8 2.0001 67.7 2.1001 67.6 2.1001C67.5 2.1001 67.4 2.1001 67.3 2.1001C67.2 2.1001 67.1 2.0001 67 2.0001C66.9 1.9001 66.9 1.9001 66.8 1.8001C66.7 1.7001 66.7 1.6001 66.7 1.5001C66.7 1.4001 66.7 1.3001 66.7 1.2001C66.7 1.1001 66.8 1.0001 66.8 0.900098C66.8 0.800098 66.9 0.800098 67 0.700098C67.1 0.700098 67.2 0.600098 67.3 0.600098C67.4 0.600098 67.5 0.600098 67.6 0.600098C67.7 0.600098 67.8 0.700098 67.9 0.700098C68 0.800098 68 0.800098 68.1 0.900098C68.1 1.0001 68.2 1.1001 68.2 1.2001C68.3 1.4001 68.3 1.5001 68.3 1.6001Z"
          fill="#F1CBB6"
        />
        <path
          d="M71.8008 4.69991C72.1008 4.79991 72.4008 4.99991 72.6008 5.19991C72.8008 5.39991 73.0008 5.69991 73.1008 5.99991C73.2008 6.29991 73.3008 6.59991 73.3008 6.99991C73.3008 7.39991 73.2008 7.69991 73.1008 8.09991C73.0008 8.29991 73.0008 8.29991 72.9008 8.39991C72.8008 8.39991 72.8008 8.39991 72.7008 8.39991L69.0008 6.89991C68.9008 7.29991 68.8008 7.59991 68.8008 7.89991C68.8008 8.19991 68.8008 8.49991 68.9008 8.69991C69.0008 8.89991 69.1008 9.09991 69.3008 9.29991C69.5008 9.49991 69.7008 9.59991 70.0008 9.69991C70.2008 9.79991 70.5008 9.89991 70.7008 9.89991C70.9008 9.89991 71.1008 9.89991 71.2008 9.89991C71.4008 9.89991 71.5008 9.89991 71.6008 9.79991C71.7008 9.79991 71.8008 9.79991 71.9008 9.79991C72.0008 9.79991 72.0008 9.89991 72.0008 9.99991L72.1008 10.4999C71.9008 10.5999 71.7008 10.6999 71.5008 10.6999C71.3008 10.6999 71.1008 10.7999 70.9008 10.6999C70.7008 10.5999 70.5008 10.6999 70.2008 10.5999C70.0008 10.5999 69.8008 10.4999 69.6008 10.3999C69.2008 10.1999 68.9008 9.99991 68.6008 9.79991C68.3008 9.49991 68.1008 9.19991 68.0008 8.89991C67.9008 8.59991 67.8008 8.19991 67.8008 7.79991C67.8008 7.39991 67.9008 6.89991 68.1008 6.49991C68.3008 6.09991 68.5008 5.79991 68.7008 5.49991C69.0008 5.19991 69.2008 4.99991 69.6008 4.79991C69.9008 4.59991 70.3008 4.59991 70.6008 4.49991C70.9008 4.39991 71.4008 4.59991 71.8008 4.69991ZM71.6008 5.39991C71.1008 5.19991 70.7008 5.19991 70.3008 5.39991C69.9008 5.59991 69.6008 5.89991 69.3008 6.29991L72.3008 7.49991C72.4008 7.29991 72.4008 7.09991 72.5008 6.79991C72.5008 6.59991 72.5008 6.39991 72.4008 6.19991C72.3008 5.99991 72.2008 5.89991 72.1008 5.69991C72.0008 5.59991 71.8008 5.49991 71.6008 5.39991Z"
          fill="#F1CBB6"
        />
        <path
          d="M74.3998 12.7003C73.9998 12.5003 73.6998 12.2003 73.5998 11.8003C73.4998 11.4003 73.5998 11.1003 73.7998 10.6003L75.5998 7.50029L74.9998 7.20029C74.8998 7.20029 74.8998 7.10029 74.8998 7.10029C74.8998 7.10029 74.8998 7.00029 74.8998 6.90029L75.0998 6.50029L75.9998 6.90029L77.0998 5.40029L77.1998 5.30029C77.2998 5.30029 77.2998 5.30029 77.3998 5.30029L77.8998 5.60029L76.8998 7.40029L78.3998 8.20029L77.9998 8.90029L76.4998 8.00029L74.7998 11.1003C74.6998 11.3003 74.5998 11.5003 74.6998 11.7003C74.6998 11.9003 74.8998 12.0003 74.9998 12.1003C75.0998 12.2003 75.1998 12.2003 75.2998 12.2003C75.3998 12.2003 75.4998 12.2003 75.4998 12.2003C75.5998 12.2003 75.5998 12.2003 75.6998 12.2003H75.7998C75.7998 12.2003 75.8998 12.3003 75.8998 12.4003V13.0003C75.6998 13.1003 75.3998 13.1003 75.0998 13.0003C74.7998 12.9003 74.6998 12.9003 74.3998 12.7003Z"
          fill="#F1CBB6"
        />
        <path
          d="M80.5 11.7002L82.8 13.4002L82.3 14.1002L80 12.4002L80.5 11.7002Z"
          fill="#F1CBB6"
        />
        <path
          d="M86.8 14L87.4 14.6C87.5 14.7 87.5 14.7 87.5 14.8C87.5 14.9 87.5 14.9 87.5 15L85.9 18.8C85.8 18.9 85.8 19.1 85.7 19.2C85.6 19.3 85.5 19.4 85.5 19.6C85.6 19.5 85.7 19.5 85.9 19.4C86 19.3 86.2 19.3 86.3 19.2L90.1 17.6C90.2 17.6 90.2 17.6 90.3 17.6C90.4 17.6 90.4 17.6 90.5 17.7L91.1 18.3L85.2 20.8L84.5 20.1L86.8 14Z"
          fill="#F1CBB6"
        />
        <path
          d="M93.3004 21.0003C93.6004 21.3003 93.7004 21.7003 93.8004 22.1003C93.9004 22.5003 93.9004 22.8003 93.9004 23.2003C93.9004 23.6003 93.7004 23.9003 93.5004 24.2003C93.3004 24.5003 93.0004 24.8003 92.6004 25.1003C92.2004 25.4003 91.9004 25.6003 91.5004 25.7003C91.1004 25.8003 90.8004 25.9003 90.4004 25.8003C90.0004 25.8003 89.7004 25.7003 89.4004 25.5003C89.1004 25.3003 88.8004 25.0003 88.5004 24.7003C88.2004 24.4003 88.1004 24.0003 88.0004 23.6003C87.9004 23.2003 87.9004 22.9003 87.9004 22.5003C88.0004 22.1003 88.1004 21.8003 88.3004 21.5003C88.5004 21.2003 88.8004 20.9003 89.2004 20.6003C89.6004 20.3003 89.9004 20.1003 90.3004 20.0003C90.7004 19.9003 91.1004 19.8003 91.4004 19.9003C91.8004 19.9003 92.1004 20.0003 92.4004 20.2003C92.7004 20.4003 93.0004 20.7003 93.3004 21.0003ZM89.1004 24.3003C89.5004 24.8003 89.9004 25.0003 90.4004 25.0003C90.9004 25.0003 91.4004 24.8003 92.0004 24.3003C92.6004 23.9003 92.9004 23.4003 93.0004 22.9003C93.1004 22.4003 93.0004 22.0003 92.7004 21.5003C92.5004 21.3003 92.3004 21.1003 92.1004 21.0003C91.9004 20.9003 91.7004 20.8003 91.4004 20.8003C91.2004 20.8003 90.9004 20.9003 90.6004 21.0003C90.3004 21.1003 90.1004 21.3003 89.8004 21.5003C89.5004 21.7003 89.3004 21.9003 89.1004 22.2003C88.9004 22.4003 88.8004 22.7003 88.7004 22.9003C88.6004 23.1003 88.6004 23.4003 88.7004 23.6003C88.8004 23.8003 88.9004 24.0003 89.1004 24.3003Z"
          fill="#F1CBB6"
        />
        <path
          d="M96.4008 25.8001L91.3008 28.9001L90.8008 28.0001L95.9008 24.9001L96.4008 25.8001ZM98.1008 25.0001C98.0008 25.1001 97.9008 25.1001 97.8008 25.1001C97.7008 25.1001 97.6008 25.1001 97.5008 25.1001C97.4008 25.1001 97.3008 25.0001 97.2008 25.0001C97.1008 24.9001 97.1008 24.9001 97.0008 24.8001C96.9008 24.7001 96.9008 24.6001 96.9008 24.5001C96.9008 24.4001 96.9008 24.3001 96.9008 24.2001C96.9008 24.1001 97.0008 24.0001 97.0008 23.9001C97.1008 23.8001 97.1008 23.8001 97.2008 23.7001C97.3008 23.6001 97.4008 23.6001 97.5008 23.6001C97.6008 23.6001 97.7008 23.6001 97.8008 23.6001C97.9008 23.6001 98.0008 23.7001 98.1008 23.7001C98.2008 23.8001 98.2008 23.8001 98.3008 23.9001C98.4008 24.0001 98.4008 24.1001 98.4008 24.2001C98.4008 24.3001 98.4008 24.4001 98.4008 24.5001C98.4008 24.6001 98.3008 24.7001 98.3008 24.8001C98.3008 24.9001 98.2008 24.9001 98.1008 25.0001Z"
          fill="#F1CBB6"
        />
        <path
          d="M92.1992 30.3001L97.4992 27.6001L97.7992 28.1001C97.8992 28.2001 97.8992 28.3001 97.7992 28.3001C97.7992 28.4001 97.6992 28.4001 97.5992 28.5001L96.7992 29.0001C97.2992 29.0001 97.6992 29.1001 97.9992 29.2001C98.2992 29.3001 98.5992 29.6001 98.7992 30.0001C98.8992 30.1001 98.8992 30.3001 98.9992 30.4001C98.9992 30.5001 98.9992 30.7001 98.9992 30.8001L98.1992 31.0001C98.0992 31.0001 97.9992 31.0001 97.9992 30.9001C97.9992 30.9001 97.9992 30.8001 97.8992 30.6001C97.8992 30.5001 97.7992 30.3001 97.6992 30.2001C97.4992 29.9001 97.2992 29.7001 96.9992 29.5001C96.6992 29.3001 96.2992 29.3001 95.8992 29.4001L92.4992 31.1001L92.1992 30.3001Z"
          fill="#F1CBB6"
        />
        <path
          d="M103.5 34.7002L95.3 37.6002L95 36.6002L103.2 33.7002L103.5 34.7002Z"
          fill="#F1CBB6"
        />
        <path
          d="M102.4 39.9003C102.5 40.2003 102.5 40.6003 102.5 40.9003C102.5 41.2003 102.4 41.5003 102.2 41.8003C102 42.1003 101.8 42.3003 101.5 42.5003C101.2 42.7003 100.9 42.8003 100.4 43.0003C100.2 43.0003 100.1 43.0003 100.1 43.0003C100.1 43.0003 100 42.9003 100 42.8003L99.0004 39.0003C98.6004 39.1003 98.3004 39.2003 98.1004 39.4003C97.9004 39.6003 97.7004 39.7003 97.5004 39.9003C97.4004 40.1003 97.3004 40.3003 97.2004 40.6003C97.2004 40.8003 97.2004 41.1003 97.2004 41.4003C97.3004 41.7003 97.3004 41.9003 97.5004 42.0003C97.6004 42.2003 97.7004 42.3003 97.8004 42.4003C97.9004 42.5003 98.0004 42.6003 98.1004 42.7003C98.2004 42.8003 98.2004 42.8003 98.2004 42.9003C98.2004 43.0003 98.2004 43.1003 98.2004 43.1003L97.9004 43.5003C97.7004 43.4003 97.5004 43.3003 97.4004 43.1003C97.2004 43.0003 97.1004 42.8003 97.0004 42.6003C96.9004 42.4003 96.8004 42.2003 96.7004 42.0003C96.6004 41.8003 96.5004 41.6003 96.5004 41.4003C96.4004 41.0003 96.4004 40.6003 96.4004 40.2003C96.5004 39.8003 96.6004 39.5003 96.8004 39.2003C97.0004 38.9003 97.3004 38.6003 97.6004 38.4003C97.9004 38.2003 98.4004 38.0003 98.9004 37.9003C99.3004 37.8003 99.7004 37.8003 100.1 37.8003C100.5 37.8003 100.8 37.9003 101.1 38.1003C101.4 38.3003 101.7 38.5003 101.9 38.8003C102.1 39.2003 102.3 39.5003 102.4 39.9003ZM101.6 40.2003C101.5 39.7003 101.2 39.4003 100.9 39.1003C100.6 38.8003 100.1 38.8003 99.6004 38.9003L100.4 42.0003C100.6 41.9003 100.8 41.9003 101 41.7003C101.2 41.6003 101.3 41.5003 101.4 41.3003C101.5 41.1003 101.6 41.0003 101.6 40.8003C101.7 40.6003 101.7 40.4003 101.6 40.2003Z"
          fill="#F1CBB6"
        />
        <path
          d="M95.5 47.1001L103.5 46.6001V47.2001C103.5 47.3001 103.4 47.4001 103.3 47.5001L102.6 47.6001C102.9 47.8001 103.2 48.1001 103.4 48.4001C103.6 48.7001 103.7 49.1001 103.7 49.5001C103.7 49.8001 103.7 50.2001 103.6 50.4001C103.5 50.7001 103.3 50.9001 103.1 51.1001C102.9 51.3001 102.5 51.5001 102.2 51.6001C101.8 51.7001 101.4 51.8001 100.9 51.8001C100.5 51.8001 100.1 51.8001 99.7 51.7001C99.3 51.6001 99 51.5001 98.7 51.3001C98.4 51.1001 98.2 50.8001 98 50.5001C97.8 50.2001 97.7 49.8001 97.7 49.4001C97.7 49.0001 97.7 48.7001 97.8 48.4001C97.9 48.1001 98.1 47.9001 98.3 47.7001L95.6 48.2001L95.5 47.1001ZM102.9 49.4001C102.9 49.1001 102.8 48.8001 102.6 48.5001C102.4 48.3001 102.2 48.0001 101.9 47.8001L99.1 48.0001C98.9 48.2001 98.7 48.4001 98.6 48.7001C98.5 49.0001 98.5 49.2001 98.5 49.5001C98.5 50.1001 98.8 50.5001 99.2 50.7001C99.6 51.0001 100.2 51.1001 100.9 51.0001C101.3 51.0001 101.6 50.9001 101.9 50.8001C102.2 50.7001 102.4 50.6001 102.6 50.5001C102.8 50.4001 102.9 50.2001 103 50.0001C102.9 49.8001 102.9 49.6001 102.9 49.4001Z"
          fill="#F1CBB6"
        />
        <path
          d="M97.7992 53.3003L103.699 53.6003V54.2003C103.699 54.3003 103.699 54.4003 103.599 54.4003C103.499 54.4003 103.499 54.5003 103.399 54.5003H102.499C102.899 54.7003 103.199 55.0003 103.399 55.3003C103.599 55.6003 103.699 56.0003 103.699 56.4003C103.699 56.6003 103.699 56.7003 103.599 56.8003C103.499 56.9003 103.499 57.0003 103.399 57.2003L102.599 57.0003C102.499 57.0003 102.499 56.9003 102.499 56.8003C102.499 56.7003 102.499 56.7003 102.599 56.6003C102.699 56.5003 102.699 56.3003 102.699 56.1003C102.699 55.7003 102.599 55.4003 102.399 55.2003C102.199 54.9003 101.899 54.7003 101.499 54.5003L97.6992 54.3003L97.7992 53.3003Z"
          fill="#F1CBB6"
        />
        <path
          d="M103.001 61.2002C102.901 61.6002 102.801 62.0002 102.601 62.3002C102.401 62.6002 102.101 62.9002 101.801 63.1002C101.501 63.3002 101.101 63.4002 100.701 63.5002C100.301 63.6002 99.9008 63.6002 99.4008 63.5002C98.9008 63.4002 98.5008 63.3002 98.2008 63.1002C97.9008 62.9002 97.6008 62.7002 97.4008 62.4002C97.2008 62.1002 97.0008 61.8002 97.0008 61.4002C96.9008 61.0002 96.9008 60.6002 97.0008 60.2002C97.1008 59.8002 97.2008 59.4002 97.4008 59.1002C97.6008 58.8002 97.9008 58.5002 98.2008 58.3002C98.5008 58.1002 98.9008 58.0002 99.2008 57.9002C99.6008 57.8002 100.001 57.8002 100.501 57.9002C101.001 58.0002 101.401 58.1002 101.701 58.3002C102.001 58.5002 102.301 58.7002 102.601 59.0002C102.801 59.3002 103.001 59.6002 103.001 60.0002C103.101 60.4002 103.101 60.8002 103.001 61.2002ZM97.8008 60.3002C97.7008 60.9002 97.8008 61.3002 98.1008 61.7002C98.4008 62.1002 99.0008 62.3002 99.6008 62.4002C100.301 62.5002 100.901 62.5002 101.301 62.3002C101.701 62.1002 102.001 61.7002 102.101 61.1002C102.201 60.8002 102.101 60.5002 102.101 60.3002C102.001 60.1002 101.901 59.9002 101.801 59.7002C101.601 59.5002 101.401 59.4002 101.201 59.3002C100.901 59.2002 100.601 59.1002 100.301 59.0002C100.001 58.9002 99.6008 58.9002 99.3008 58.9002C99.0008 58.9002 98.8008 59.0002 98.6008 59.1002C98.4008 59.2002 98.2008 59.4002 98.1008 59.6002C97.9008 59.7002 97.8008 60.0002 97.8008 60.3002Z"
          fill="#F1CBB6"
        />
        <path
          d="M101.601 66.7002L95.5008 64.9002C95.3008 64.8002 95.1008 64.7002 94.9008 64.6002C94.7008 64.5002 94.6008 64.3002 94.5008 64.2002C94.4008 64.1002 94.3008 63.8002 94.3008 63.6002C94.3008 63.4002 94.3008 63.1002 94.4008 62.8002C94.4008 62.7002 94.5008 62.6002 94.5008 62.5002C94.5008 62.4002 94.6008 62.3002 94.7008 62.2002L95.2008 62.4002C95.2008 62.4002 95.3008 62.4002 95.3008 62.5002C95.3008 62.6002 95.3008 62.6002 95.3008 62.6002V62.7002V62.8002C95.2008 63.1002 95.2008 63.3002 95.3008 63.5002C95.4008 63.7002 95.6008 63.8002 95.9008 63.9002L102.001 65.7002L101.601 66.7002ZM103.301 67.4002C103.201 67.4002 103.101 67.3002 103.001 67.3002C102.901 67.2002 102.901 67.2002 102.801 67.1002C102.701 67.0002 102.701 66.9002 102.701 66.8002C102.701 66.7002 102.701 66.6002 102.701 66.5002C102.701 66.4002 102.801 66.3002 102.801 66.2002C102.901 66.1002 102.901 66.1002 103.001 66.0002C103.101 66.0002 103.201 65.9002 103.301 65.9002C103.401 65.9002 103.501 65.9002 103.601 65.9002C103.701 65.9002 103.801 66.0002 103.901 66.0002C104.001 66.1002 104.001 66.1002 104.101 66.2002C104.201 66.3002 104.201 66.4002 104.201 66.5002C104.201 66.6002 104.201 66.7002 104.201 66.8002C104.201 66.9002 104.101 67.0002 104.101 67.1002C104.001 67.2002 104.001 67.2002 103.901 67.3002C103.801 67.3002 103.701 67.4002 103.601 67.4002C103.501 67.5002 103.401 67.5002 103.301 67.4002Z"
          fill="#F1CBB6"
        />
        <path
          d="M100.2 71.1002C100.1 71.4002 99.8996 71.7002 99.6996 72.0002C99.4996 72.2002 99.1996 72.4002 98.8996 72.5002C98.5996 72.6002 98.2996 72.7002 97.8996 72.7002C97.4996 72.7002 97.1996 72.6002 96.7996 72.5002C96.5996 72.4002 96.5996 72.4002 96.4996 72.3002C96.4996 72.2002 96.4996 72.2002 96.4996 72.1002L97.9996 68.4002C97.5996 68.3002 97.2996 68.2002 96.9996 68.2002C96.6996 68.2002 96.3996 68.2002 96.1996 68.3002C95.9996 68.4002 95.7996 68.5002 95.5996 68.7002C95.3996 68.9002 95.2996 69.1002 95.1996 69.4002C95.0996 69.6002 94.9996 69.9002 94.9996 70.1002C94.9996 70.3002 94.9996 70.5002 94.9996 70.6002C94.9996 70.8002 94.9996 70.9002 95.0996 71.0002C95.0996 71.1002 95.0996 71.2002 95.0996 71.3002C95.0996 71.4002 94.9996 71.4002 94.8996 71.4002L94.3996 71.5002C94.2996 71.3002 94.1996 71.1002 94.1996 70.9002C94.1996 70.7002 94.0996 70.5002 94.0996 70.3002C94.0996 70.1002 94.0996 69.9002 94.1996 69.6002C94.2996 69.3002 94.2996 69.2002 94.3996 69.0002C94.5996 68.6002 94.7996 68.3002 94.9996 68.0002C95.2996 67.7002 95.5996 67.5002 95.8996 67.4002C96.1996 67.3002 96.5996 67.2002 96.9996 67.2002C97.3996 67.2002 97.8996 67.3002 98.2996 67.5002C98.6996 67.7002 98.9996 67.9002 99.2996 68.1002C99.5996 68.3002 99.7996 68.6002 99.9996 69.0002C100.2 69.4002 100.2 69.7002 100.3 70.0002C100.4 70.3002 100.4 70.7002 100.2 71.1002ZM99.4996 70.8002C99.6996 70.3002 99.6996 69.9002 99.4996 69.5002C99.2996 69.1002 98.9996 68.8002 98.5996 68.6002L97.3996 71.6002C97.5996 71.7002 97.7996 71.7002 98.0996 71.8002C98.2996 71.8002 98.4996 71.8002 98.6996 71.7002C98.8996 71.6002 98.9996 71.5002 99.1996 71.4002C99.2996 71.2002 99.3996 71.0002 99.4996 70.8002Z"
          fill="#F1CBB6"
        />
        <path
          d="M92.1992 73.6C92.3992 73.2 92.6992 72.9 93.0992 72.8C93.3992 72.7 93.7992 72.8 94.2992 73L97.4992 74.7L97.7992 74.1C97.7992 74 97.8992 74 97.8992 74C97.9992 74 97.9992 74 98.0992 74L98.4992 74.2L98.1992 75.1L99.6992 76.2L99.7992 76.3C99.7992 76.3 99.7992 76.4 99.7992 76.5L99.4992 77L97.6992 76L96.8992 77.5L96.1992 77.1L96.9992 75.6L93.7992 74C93.5992 73.9 93.3992 73.8 93.1992 73.9C92.9992 74 92.8992 74.1 92.7992 74.2C92.6992 74.3 92.6992 74.4 92.6992 74.5C92.6992 74.6 92.6992 74.7 92.6992 74.7C92.6992 74.8 92.6992 74.8 92.6992 74.9C92.6992 75 92.6992 75 92.6992 75C92.6992 75 92.5992 75.1 92.4992 75.1H91.8992C91.7992 74.9 91.7992 74.6 91.8992 74.3C91.9992 74 92.0992 73.9 92.1992 73.6Z"
          fill="#F1CBB6"
        />
        <path
          d="M93.3004 79.7002L91.6004 82.0002L90.9004 81.5002L92.6004 79.2002L93.3004 79.7002Z"
          fill="#F1CBB6"
        />
        <path
          d="M90.8996 86.0002L90.2996 86.6002C90.1996 86.7002 90.1996 86.7002 90.0996 86.7002C89.9996 86.7002 89.9996 86.7002 89.8996 86.7002L86.0996 85.1002C85.9996 85.0002 85.7996 85.0002 85.6996 84.9002C85.5996 84.8002 85.4996 84.7002 85.2996 84.7002C85.3996 84.8002 85.4996 84.9002 85.4996 85.1002C85.4996 85.3002 85.5996 85.4002 85.6996 85.5002L87.2996 89.3002C87.2996 89.4002 87.2996 89.4002 87.2996 89.5002C87.2996 89.6002 87.2996 89.6002 87.1996 89.7002L86.5996 90.3002L84.0996 84.4002L84.7996 83.7002L90.8996 86.0002Z"
          fill="#F1CBB6"
        />
        <path
          d="M83.9996 92.5C83.6996 92.8 83.2996 92.9 82.8996 93C82.4996 93.1 82.1996 93.1 81.7996 93.1C81.3996 93.1 81.0996 92.9 80.7996 92.7C80.4996 92.5 80.1996 92.2 79.8996 91.8C79.5996 91.4 79.3996 91.1 79.2996 90.7C79.1996 90.3 79.0996 90 79.1996 89.6C79.2996 89.2 79.2996 88.9 79.4996 88.6C79.6996 88.3 79.9996 88 80.2996 87.7C80.5996 87.4 80.9996 87.2 81.3996 87.1C81.7996 87 82.0996 87 82.4996 87C82.8996 87 83.1996 87.2 83.4996 87.4C83.7996 87.6 84.0996 87.9 84.3996 88.3C84.6996 88.7 84.8996 89 84.9996 89.4C85.0996 89.8 85.1996 90.2 85.0996 90.5C85.0996 90.9 84.9996 91.2 84.7996 91.5C84.5996 92 84.2996 92.3 83.9996 92.5ZM80.6996 88.3C80.1996 88.7 79.9996 89.1 79.9996 89.6C79.9996 90.1 80.1996 90.6 80.6996 91.2C81.0996 91.8 81.5996 92.1 82.0996 92.2C82.5996 92.3 82.9996 92.2 83.4996 91.9C83.6996 91.7 83.8996 91.5 83.9996 91.3C84.0996 91.1 84.1996 90.9 84.1996 90.6C84.1996 90.4 84.0996 90.1 83.9996 89.8C83.8996 89.5 83.6996 89.3 83.4996 89C83.2996 88.7 83.0996 88.5 82.7996 88.3C82.4996 88.1 82.2996 88 82.0996 87.9C81.8996 87.8 81.5996 87.8 81.3996 87.9C81.1996 88 80.9996 88.2 80.6996 88.3Z"
          fill="#F1CBB6"
        />
        <path
          d="M79.2996 95.7001L76.0996 90.7001L76.9996 90.1001L80.1996 95.1001L79.2996 95.7001ZM80.0996 97.4001C79.9996 97.3001 79.9996 97.2001 79.9996 97.1001C79.9996 97.0001 79.9996 96.9001 79.9996 96.8001C79.9996 96.7001 80.0996 96.6001 80.0996 96.5001C80.0996 96.4001 80.1996 96.4001 80.2996 96.3001C80.3996 96.2001 80.4996 96.2001 80.5996 96.2001C80.6996 96.2001 80.7996 96.2001 80.8996 96.2001C80.9996 96.2001 81.0996 96.3001 81.1996 96.3001C81.2996 96.3001 81.2996 96.4001 81.3996 96.5001C81.4996 96.6001 81.4996 96.7001 81.4996 96.8001C81.4996 96.9001 81.4996 97.0001 81.4996 97.1001C81.4996 97.2001 81.3996 97.3001 81.3996 97.4001C81.2996 97.5001 81.2996 97.5001 81.1996 97.6001C81.0996 97.7001 80.9996 97.7001 80.8996 97.7001C80.7996 97.7001 80.6996 97.7001 80.5996 97.7001C80.4996 97.7001 80.3996 97.6001 80.2996 97.6001C80.1996 97.6001 80.0996 97.5001 80.0996 97.4001Z"
          fill="#F1CBB6"
        />
        <path
          d="M74.6992 91.5L77.3992 96.8L76.8992 97.1C76.7992 97.2 76.6992 97.2 76.6992 97.1C76.6992 97 76.5992 97 76.4992 96.9L75.9992 96.1C75.9992 96.6 75.8992 97 75.7992 97.3C75.5992 97.6 75.3992 97.9 74.9992 98.1C74.8992 98.2 74.6992 98.2 74.5992 98.3C74.4992 98.3 74.2992 98.3 74.1992 98.3L73.9992 97.5C73.9992 97.4 73.9992 97.3 74.0992 97.3C74.0992 97.3 74.1992 97.3 74.2992 97.2C74.3992 97.2 74.5992 97.1 74.6992 97C74.9992 96.8 75.1992 96.6 75.3992 96.3C75.4992 96 75.5992 95.6 75.4992 95.2L73.6992 92L74.6992 91.5Z"
          fill="#F1CBB6"
        />
        <path
          d="M70.4 102.9L67.5 94.7999L68.5 94.3999L71.4 102.5L70.4 102.9Z"
          fill="#F1CBB6"
        />
        <path
          d="M65.1 101.7C64.8 101.8 64.4 101.8 64.1 101.8C63.8 101.8 63.5 101.7 63.2 101.5C62.9 101.3 62.7 101.1 62.5 100.8C62.3 100.5 62.1 100.2 62 99.7003C62 99.5003 62 99.4003 62 99.4003C62 99.3003 62.1 99.3003 62.2 99.3003L66 98.3003C65.9 97.9003 65.8 97.6003 65.6 97.4003C65.4 97.2003 65.3 97.0003 65.1 96.8003C64.9 96.7003 64.7 96.6003 64.4 96.5003C64.2 96.5003 63.9 96.5003 63.6 96.6003C63.3 96.7003 63.1 96.7003 63 96.9003C62.8 97.0003 62.7 97.1003 62.6 97.2003C62.5 97.3003 62.4 97.4003 62.3 97.5003C62.2 97.6003 62.2 97.6003 62.1 97.6003C62 97.6003 61.9 97.6003 61.9 97.6003L61.5 97.3003C61.6 97.1003 61.7 96.9003 61.9 96.8003C62.1 96.7003 62.2 96.5003 62.4 96.4003C62.6 96.3003 62.8 96.2003 63 96.1003C63.2 96.0003 63.4 95.9003 63.6 95.9003C64 95.8003 64.4 95.8003 64.8 95.8003C65.2 95.8003 65.5 96.0003 65.8 96.2003C66.1 96.4003 66.4 96.7003 66.6 97.0003C66.8 97.3003 67 97.8003 67.1 98.3003C67.2 98.7003 67.2 99.1003 67.2 99.5003C67.2 99.9003 67.1 100.2 66.9 100.5C66.7 100.8 66.5 101.1 66.2 101.3C65.9 101.4 65.5 101.6 65.1 101.7ZM64.9 101C65.4 100.9 65.7 100.6 65.9 100.3C66.1 100 66.2 99.5003 66.1 99.0003L63 99.8003C63.1 100 63.1 100.2 63.3 100.4C63.4 100.6 63.6 100.7 63.7 100.8C63.9 100.9 64 101 64.2 101C64.4 101 64.6 101 64.9 101Z"
          fill="#F1CBB6"
        />
        <path
          d="M57.8992 94.8999L58.3992 102.9H57.7992C57.6992 102.9 57.5992 102.8 57.4992 102.7L57.3992 102C57.1992 102.3 56.8992 102.6 56.5992 102.8C56.2992 103 55.8992 103.1 55.4992 103.2C55.1992 103.2 54.7992 103.2 54.5992 103.1C54.2992 103 54.0992 102.8 53.8992 102.6C53.6992 102.4 53.4992 102 53.3992 101.7C53.2992 101.3 53.1992 100.9 53.1992 100.4C53.1992 99.9999 53.1992 99.5999 53.2992 99.1999C53.3992 98.7999 53.4992 98.4999 53.6992 98.1999C53.8992 97.8999 54.1992 97.6999 54.4992 97.4999C54.7992 97.2999 55.1992 97.1999 55.5992 97.1999C55.9992 97.1999 56.2992 97.1999 56.5992 97.2999C56.8992 97.3999 57.0992 97.5999 57.2992 97.7999L56.8992 94.9999L57.8992 94.8999ZM55.6992 102.3C55.9992 102.3 56.2992 102.2 56.5992 102C56.8992 101.8 57.0992 101.6 57.2992 101.3L57.0992 98.3999C56.8992 98.1999 56.6992 97.9999 56.3992 97.8999C56.0992 97.7999 55.8992 97.7999 55.5992 97.7999C54.9992 97.7999 54.5992 98.0999 54.3992 98.4999C54.1992 98.8999 53.9992 99.4999 54.0992 100.2C54.0992 100.6 54.1992 100.9 54.2992 101.2C54.3992 101.5 54.4992 101.7 54.5992 101.9C54.6992 102.1 54.8992 102.2 55.0992 102.3C55.2992 102.3 55.4992 102.3 55.6992 102.3Z"
          fill="#F1CBB6"
        />
        <path
          d="M51.8004 97.2001L51.5004 103.1H50.9004C50.8004 103.1 50.7004 103.1 50.7004 103C50.7004 103 50.6004 102.9 50.6004 102.8V101.9C50.4004 102.3 50.1004 102.6 49.8004 102.8C49.5004 103 49.1004 103.1 48.7004 103.1C48.5004 103.1 48.4004 103.1 48.3004 103C48.2004 103 48.0004 102.9 47.9004 102.8L48.1004 102C48.1004 101.9 48.2004 101.9 48.3004 101.9C48.4004 101.9 48.4004 101.9 48.5004 102C48.6004 102.1 48.8004 102.1 49.0004 102.1C49.4004 102.1 49.7004 102 49.9004 101.8C50.1004 101.6 50.4004 101.3 50.6004 100.9L50.8004 97.1001L51.8004 97.2001Z"
          fill="#F1CBB6"
        />
        <path
          d="M43.9004 102.5C43.5004 102.4 43.1004 102.3 42.8004 102.1C42.5004 101.9 42.2004 101.6 42.0004 101.3C41.8004 101 41.7004 100.6 41.6004 100.3C41.5004 99.9002 41.5004 99.5002 41.6004 99.0002C41.7004 98.5002 41.8004 98.1002 42.0004 97.8002C42.2004 97.5002 42.4004 97.2002 42.7004 96.9002C43.0004 96.7002 43.3004 96.5002 43.7004 96.4002C44.1004 96.3002 44.5004 96.3002 44.9004 96.4002C45.3004 96.5002 45.7004 96.6002 46.0004 96.8002C46.3004 97.0002 46.6004 97.3002 46.8004 97.6002C47.0004 97.9002 47.1004 98.3002 47.2004 98.6002C47.3004 99.0002 47.3004 99.4002 47.2004 99.9002C47.1004 100.4 47.0004 100.8 46.8004 101.1C46.6004 101.4 46.4004 101.7 46.1004 102C45.8004 102.2 45.5004 102.4 45.1004 102.5C44.7004 102.5 44.3004 102.5 43.9004 102.5ZM44.8004 97.2002C44.2004 97.1002 43.8004 97.2002 43.4004 97.6002C43.0004 97.9002 42.8004 98.5002 42.7004 99.1002C42.6004 99.8002 42.6004 100.4 42.8004 100.8C43.0004 101.2 43.4004 101.5 44.0004 101.6C44.3004 101.7 44.6004 101.6 44.8004 101.6C45.0004 101.5 45.2004 101.4 45.4004 101.3C45.6004 101.2 45.7004 100.9 45.8004 100.7C45.9004 100.5 46.0004 100.1 46.1004 99.8002C46.2004 99.5002 46.2004 99.1002 46.2004 98.8002C46.2004 98.5002 46.1004 98.3002 46.0004 98.1002C45.9004 97.9002 45.7004 97.7002 45.5004 97.6002C45.3004 97.4002 45.1004 97.3002 44.8004 97.2002Z"
          fill="#F1CBB6"
        />
        <path
          d="M37.5996 102.8C37.5996 102.7 37.6996 102.6 37.6996 102.5C37.7996 102.4 37.7996 102.4 37.8996 102.3C37.9996 102.3 38.0996 102.2 38.1996 102.2C38.2996 102.2 38.3996 102.2 38.4996 102.2C38.5996 102.2 38.6996 102.3 38.7996 102.3C38.8996 102.3 38.8996 102.4 38.9996 102.5C38.9996 102.6 39.0996 102.7 39.0996 102.8C39.0996 102.9 39.0996 103 39.0996 103.1C39.0996 103.2 38.9996 103.3 38.9996 103.4C38.9996 103.5 38.8996 103.5 38.7996 103.6C38.6996 103.6 38.5996 103.7 38.4996 103.7C38.3996 103.7 38.2996 103.7 38.1996 103.7C38.0996 103.7 37.9996 103.6 37.8996 103.6C37.7996 103.5 37.7996 103.5 37.6996 103.4C37.6996 103.3 37.5996 103.2 37.5996 103.1C37.5996 103 37.5996 102.9 37.5996 102.8ZM38.2996 101.1L39.9996 94.9002C40.0996 94.7002 40.1996 94.5002 40.2996 94.3002C40.3996 94.1002 40.5996 94.0002 40.6996 93.9002C40.7996 93.8002 41.0996 93.7002 41.2996 93.7002C41.4996 93.7002 41.7996 93.7002 42.0996 93.8002C42.1996 93.8002 42.2996 93.9002 42.3996 93.9002C42.4996 93.9002 42.5996 94.0002 42.6996 94.1002L42.5996 94.6002C42.5996 94.6002 42.5996 94.7002 42.4996 94.7002H42.3996H42.2996H42.1996C41.8996 94.6002 41.6996 94.6002 41.4996 94.7002C41.2996 94.8002 41.1996 95.0002 41.0996 95.3002L39.3996 101.4L38.2996 101.1Z"
          fill="#F1CBB6"
        />
        <path
          d="M34.0004 99.7002C33.7004 99.6002 33.4004 99.4002 33.1004 99.2002C32.9004 99.0002 32.7004 98.7002 32.6004 98.4002C32.5004 98.1002 32.4004 97.8002 32.4004 97.4002C32.4004 97.0002 32.5004 96.7002 32.6004 96.3002C32.7004 96.1002 32.7004 96.1002 32.8004 96.0002C32.9004 95.9002 32.9004 96.0002 33.0004 96.0002L36.7004 97.4002C36.8004 97.0002 36.9004 96.7002 36.9004 96.4002C36.9004 96.1002 36.9004 95.8002 36.8004 95.6002C36.7004 95.4002 36.6004 95.2002 36.4004 95.0002C36.2004 94.8002 36.0004 94.7002 35.7004 94.6002C35.5004 94.5002 35.2004 94.4002 35.0004 94.4002C34.8004 94.4002 34.6004 94.4002 34.5004 94.4002C34.3004 94.4002 34.2004 94.4002 34.1004 94.5002C34.0004 94.6002 33.9004 94.5002 33.8004 94.5002C33.7004 94.5002 33.7004 94.4002 33.7004 94.3002V94.0002C33.9004 93.9002 34.1004 93.8002 34.3004 93.8002C34.5004 93.8002 34.7004 93.7002 34.9004 93.7002C35.1004 93.7002 35.3004 93.7002 35.6004 93.8002C35.9004 93.9002 36.0004 93.9002 36.2004 94.0002C36.6004 94.1002 36.9004 94.4002 37.2004 94.6002C37.5004 94.9002 37.7004 95.2002 37.8004 95.5002C37.9004 95.8002 38.0004 96.2002 38.0004 96.6002C38.0004 97.0002 37.9004 97.5002 37.7004 97.9002C37.5004 98.3002 37.3004 98.6002 37.1004 98.9002C36.9004 99.2002 36.6004 99.4002 36.2004 99.6002C35.9004 99.8002 35.5004 99.8002 35.2004 99.9002C34.9004 100 34.4004 99.8002 34.0004 99.7002ZM34.3004 99.0002C34.8004 99.2002 35.2004 99.2002 35.6004 99.0002C36.0004 98.8002 36.3004 98.5002 36.5004 98.1002L33.5004 96.9002C33.4004 97.1002 33.4004 97.3002 33.4004 97.6002C33.4004 97.8002 33.4004 98.0002 33.5004 98.2002C33.6004 98.4002 33.7004 98.5002 33.8004 98.7002C33.9004 98.9002 34.0004 98.9002 34.3004 99.0002Z"
          fill="#F1CBB6"
        />
        <path
          d="M31.4 91.7003C31.8 91.9003 32.1 92.2003 32.2 92.6003C32.3 92.9003 32.2 93.3003 32 93.8003L30.2 97.0003L30.8 97.3003C30.9 97.3003 30.9 97.4003 30.9 97.4003C30.9 97.5003 30.9 97.5003 30.9 97.6003L30.8 98.0003L29.9 97.7003L28.8 99.2003L28.7 99.3003C28.7 99.3003 28.6 99.3003 28.5 99.3003L28 99.1003L29 97.3003L27.5 96.5003L27.9 95.8003L29.4 96.6003L31.1 93.4003C31.2 93.2003 31.2 93.0003 31.2 92.8003C31.2 92.6003 31 92.5003 30.9 92.4003C30.8 92.3003 30.7 92.3003 30.6 92.3003C30.5 92.3003 30.4 92.3003 30.4 92.3003C30.3 92.3003 30.3 92.3003 30.2 92.3003H30.1C30.1 92.3003 30 92.2003 30 92.1003V91.5003C30.2 91.4003 30.5 91.4003 30.8 91.5003C30.9 91.5003 31.1 91.6003 31.4 91.7003Z"
          fill="#F1CBB6"
        />
        <path
          d="M25.3004 92.7999L22.9004 91.0999L23.4004 90.3999L25.8004 92.0999L25.3004 92.7999Z"
          fill="#F1CBB6"
        />
        <path
          d="M18.9992 90.5003L18.3992 89.9003C18.2992 89.8003 18.2992 89.8003 18.2992 89.7003C18.2992 89.6003 18.2992 89.6003 18.2992 89.5003L19.8992 85.7003C19.9992 85.6003 19.9992 85.4003 20.0992 85.3003C20.1992 85.2003 20.2992 85.1003 20.2992 84.9003C20.1992 85.0003 20.0992 85.1003 19.8992 85.1003C19.7992 85.2003 19.5992 85.2003 19.4992 85.3003L15.6992 87.0003C15.5992 87.0003 15.5992 87.0003 15.4992 87.0003C15.3992 87.0003 15.3992 87.0003 15.2992 86.9003L14.6992 86.3003L20.5992 83.8003L21.2992 84.5003L18.9992 90.5003Z"
          fill="#F1CBB6"
        />
        <path
          d="M12.3992 83.6002C12.0992 83.3002 11.8992 82.9002 11.7992 82.5002C11.6992 82.1002 11.6992 81.8002 11.6992 81.4002C11.7992 81.0002 11.8992 80.7002 12.0992 80.4002C12.2992 80.1002 12.5992 79.8002 12.9992 79.5002C13.3992 79.2002 13.6992 79.0002 14.0992 78.9002C14.4992 78.8002 14.7992 78.7002 15.1992 78.8002C15.5992 78.8002 15.8992 78.9002 16.1992 79.1002C16.4992 79.3002 16.7992 79.6002 17.0992 79.9002C17.3992 80.2002 17.5992 80.6002 17.6992 81.0002C17.7992 81.4002 17.7992 81.7002 17.7992 82.1002C17.7992 82.5002 17.5992 82.8002 17.3992 83.1002C17.1992 83.4002 16.8992 83.7002 16.4992 84.0002C16.0992 84.3002 15.7992 84.5002 15.3992 84.6002C14.9992 84.7002 14.5992 84.8002 14.2992 84.7002C13.9992 84.6002 13.5992 84.6002 13.2992 84.4002C12.9992 84.2002 12.6992 83.9002 12.3992 83.6002ZM16.5992 80.3002C16.1992 79.8002 15.7992 79.6002 15.2992 79.6002C14.7992 79.6002 14.2992 79.8002 13.6992 80.3002C13.0992 80.7002 12.7992 81.2002 12.6992 81.7002C12.5992 82.2002 12.6992 82.6002 12.9992 83.1002C13.1992 83.3002 13.3992 83.5002 13.5992 83.6002C13.7992 83.7002 13.9992 83.8002 14.2992 83.8002C14.4992 83.8002 14.7992 83.7002 15.0992 83.6002C15.3992 83.5002 15.5992 83.3002 15.8992 83.1002C16.1992 82.9002 16.3992 82.7002 16.5992 82.4002C16.7992 82.2002 16.8992 81.9002 16.9992 81.7002C17.0992 81.5002 17.0992 81.2002 16.9992 81.0002C16.8992 80.8002 16.7992 80.5002 16.5992 80.3002Z"
          fill="#F1CBB6"
        />
        <path
          d="M7.59922 79.7002C7.69922 79.6002 7.79922 79.6002 7.89922 79.6002C7.99922 79.6002 8.09922 79.6002 8.19922 79.6002C8.29922 79.6002 8.39922 79.7002 8.49922 79.7002C8.59922 79.8002 8.59922 79.8002 8.69922 79.9002C8.59922 80.0002 8.69922 80.1002 8.69922 80.2002C8.69922 80.3002 8.69922 80.4002 8.69922 80.5002C8.69922 80.6002 8.59922 80.7002 8.59922 80.8002C8.49922 80.9002 8.49922 80.9002 8.39922 81.0002C8.29922 81.0002 8.19922 81.0002 8.09922 81.0002C7.99922 81.0002 7.89922 81.0002 7.79922 81.0002C7.69922 81.0002 7.59922 81.0002 7.49922 80.9002C7.39922 80.8002 7.39922 80.8002 7.29922 80.7002C7.19922 80.6002 7.19922 80.5002 7.19922 80.4002C7.19922 80.3002 7.19922 80.2002 7.19922 80.1002C7.19922 80.0002 7.29922 79.9002 7.29922 79.8002C7.39922 79.8002 7.49922 79.7002 7.59922 79.7002ZM9.29922 78.9002L14.2992 75.7002L14.8992 76.6002L9.89922 79.8002L9.29922 78.9002Z"
          fill="#F1CBB6"
        />
        <path
          d="M13.3996 74.2002L8.19961 77.0002L7.89961 76.5002C7.79961 76.4002 7.79961 76.3002 7.79961 76.3002C7.79961 76.2002 7.89961 76.2002 7.99961 76.1002L8.79961 75.6002C8.29961 75.6002 7.89961 75.5002 7.59961 75.4002C7.29961 75.3002 6.99961 75.0002 6.79961 74.6002C6.69961 74.4002 6.59961 74.3002 6.59961 74.1002C6.59961 74.0002 6.59961 73.8002 6.59961 73.7002L7.39961 73.5002C7.49961 73.5002 7.59961 73.5002 7.59961 73.6002C7.59961 73.6002 7.59961 73.7002 7.69961 73.8002C7.79961 73.9002 7.79961 74.1002 7.89961 74.2002C8.09961 74.5002 8.29961 74.7002 8.59961 74.9002C8.89961 75.0002 9.29961 75.1002 9.69961 75.0002L13.0996 73.2002L13.3996 74.2002Z"
          fill="#F1CBB6"
        />
        <path
          d="M2.09961 70L10.1996 67L10.5996 68L2.49961 71L2.09961 70Z"
          fill="#F1CBB6"
        />
        <path
          d="M3.19961 64.7001C3.09961 64.4001 3.09961 64.0001 3.09961 63.7001C3.09961 63.4001 3.19961 63.1001 3.39961 62.8001C3.59961 62.5001 3.79961 62.3001 4.09961 62.1001C4.39961 61.9001 4.69961 61.7001 5.09961 61.6001C5.29961 61.6001 5.39961 61.5001 5.39961 61.6001C5.39961 61.7001 5.59961 61.7001 5.59961 61.8001L6.59961 65.6001C6.99961 65.5001 7.29961 65.4001 7.49961 65.2001C7.69961 65.0001 7.89961 64.9001 8.09961 64.7001C8.19961 64.5001 8.29961 64.3001 8.39961 64.0001C8.39961 63.8001 8.39961 63.5001 8.29961 63.2001C8.19961 62.9001 8.09961 62.7001 7.99961 62.6001C7.89961 62.5001 7.79961 62.3001 7.69961 62.2001C7.59961 62.1001 7.49961 62.0001 7.39961 61.9001C7.29961 61.8001 7.29961 61.8001 7.29961 61.7001C7.29961 61.6001 7.29961 61.5001 7.29961 61.5001L7.59961 61.1001C7.79961 61.2001 7.99961 61.3001 8.09961 61.4001C8.29961 61.5001 8.39961 61.7001 8.49961 61.9001C8.59961 62.1001 8.69961 62.3001 8.79961 62.5001C8.89961 62.7001 8.99961 62.9001 8.99961 63.1001C9.09961 63.5001 9.09961 63.9001 9.09961 64.3001C9.09961 64.7001 8.89961 65.0001 8.69961 65.3001C8.49961 65.6001 8.19961 65.9001 7.89961 66.1001C7.59961 66.3001 7.09961 66.5001 6.59961 66.6001C6.19961 66.7001 5.79961 66.7001 5.39961 66.7001C4.99961 66.7001 4.69961 66.6001 4.39961 66.4001C4.09961 66.2001 3.79961 66.0001 3.59961 65.7001C3.49961 65.5001 3.29961 65.1001 3.19961 64.7001ZM3.89961 64.5001C3.99961 65.0001 4.29961 65.3001 4.59961 65.5001C4.99961 65.7001 5.39961 65.8001 5.89961 65.7001L5.09961 62.6001C4.89961 62.7001 4.69961 62.7001 4.49961 62.9001C4.29961 63.0001 4.19961 63.2001 4.09961 63.3001C3.99961 63.5001 3.89961 63.6001 3.89961 63.8001C3.89961 64.0001 3.89961 64.3001 3.89961 64.5001Z"
          fill="#F1CBB6"
        />
        <path
          d="M9.89961 57.5001L1.99961 58.0001V57.4001C1.99961 57.3001 2.09961 57.2001 2.19961 57.1001L2.79961 57.0001C2.49961 56.8001 2.19961 56.5001 1.99961 56.2001C1.79961 55.9001 1.69961 55.5001 1.59961 55.1001C1.59961 54.8001 1.59961 54.4001 1.69961 54.2001C1.79961 53.9001 1.99961 53.7001 2.19961 53.5001C2.59961 53.1001 2.89961 53.0001 3.29961 52.8001C3.59961 52.7001 4.09961 52.6001 4.59961 52.6001C4.99961 52.6001 5.39961 52.6001 5.79961 52.7001C6.19961 52.8001 6.49961 52.9001 6.79961 53.1001C7.09961 53.3001 7.29961 53.6001 7.49961 53.9001C7.69961 54.2001 7.79961 54.6001 7.79961 55.0001C7.79961 55.4001 7.79961 55.7001 7.69961 56.0001C7.59961 56.3001 7.39961 56.5001 7.19961 56.7001L9.79961 56.5001L9.89961 57.5001ZM2.59961 55.3001C2.59961 55.6001 2.69961 55.9001 2.89961 56.2001C3.09961 56.4001 3.29961 56.7001 3.59961 56.9001L6.49961 56.7001C6.69961 56.5001 6.89961 56.3001 6.99961 56.0001C7.09961 55.8001 7.09961 55.5001 7.09961 55.2001C7.09961 54.6001 6.79961 54.2001 6.39961 54.0001C5.99961 53.7001 5.39961 53.6001 4.69961 53.7001C4.29961 53.7001 3.99961 53.8001 3.69961 53.9001C3.39961 54.0001 3.19961 54.1001 2.99961 54.2001C2.79961 54.3001 2.69961 54.5001 2.59961 54.7001C2.49961 54.9001 2.49961 55.1001 2.59961 55.3001Z"
          fill="#F1CBB6"
        />
        <path
          d="M7.59922 51.2999L1.69922 50.9999V50.3999C1.69922 50.2999 1.69922 50.1999 1.79922 50.1999C1.79922 50.1999 1.89922 50.0999 1.99922 50.0999H2.89922C2.49922 49.8999 2.19922 49.5999 1.99922 49.2999C1.79922 48.9999 1.69922 48.5999 1.69922 48.1999C1.69922 47.9999 1.69922 47.8999 1.79922 47.7999C1.79922 47.6999 1.89922 47.4999 1.99922 47.3999L2.79922 47.5999C2.89922 47.5999 2.89922 47.6999 2.89922 47.7999C2.89922 47.8999 2.89922 47.8999 2.79922 47.9999C2.79922 48.0999 2.69922 48.2999 2.69922 48.4999C2.69922 48.8999 2.79922 49.1999 2.99922 49.3999C3.19922 49.6999 3.49922 49.8999 3.89922 50.0999L7.69922 50.2999L7.59922 51.2999Z"
          fill="#F1CBB6"
        />
        <path
          d="M2.30078 43.5C2.40078 43 2.60078 42.7 2.80078 42.4C3.00078 42.1 3.30078 41.8 3.60078 41.6C3.90078 41.4 4.30078 41.3 4.60078 41.2C5.00078 41.1 5.40078 41.1 5.90078 41.2C6.40078 41.3 6.80078 41.4 7.10078 41.6C7.40078 41.8 7.70078 42 8.00078 42.3C8.20078 42.6 8.40078 42.9 8.50078 43.3C8.60078 43.7 8.60078 44.1 8.50078 44.5C8.40078 44.9 8.30078 45.3 8.10078 45.6C7.90078 45.9 7.60078 46.2 7.30078 46.4C7.00078 46.6 6.60078 46.7 6.30078 46.8C5.90078 46.9 5.50078 46.9 5.00078 46.8C4.50078 46.7 4.10078 46.6 3.80078 46.4C3.40078 46.2 3.20078 46 2.90078 45.7C2.60078 45.4 2.50078 45.1 2.40078 44.7C2.30078 44.3 2.30078 43.9 2.30078 43.5ZM7.60078 44.4C7.70078 43.8 7.60078 43.4 7.20078 43C6.90078 42.6 6.30078 42.4 5.60078 42.3C4.90078 42.2 4.30078 42.2 3.90078 42.5C3.50078 42.7 3.20078 43.1 3.10078 43.7C3.10078 44 3.10078 44.3 3.10078 44.5C3.10078 44.7 3.30078 44.9 3.40078 45.1C3.60078 45.3 3.80078 45.4 4.00078 45.5C4.30078 45.6 4.60078 45.7 4.90078 45.8C5.20078 45.9 5.60078 45.9 5.90078 45.9C6.20078 45.9 6.40078 45.8 6.60078 45.7C6.80078 45.6 7.00078 45.4 7.10078 45.2C7.40078 44.9 7.50078 44.7 7.60078 44.4Z"
          fill="#F1CBB6"
        />
        <path
          d="M2 37.2001C2.1 37.2001 2.2 37.3001 2.3 37.3001C2.4 37.4001 2.4 37.4001 2.5 37.5001C2.5 37.6001 2.6 37.7001 2.6 37.8001C2.6 37.9001 2.6 38.0001 2.6 38.1001C2.6 38.2001 2.5 38.3001 2.5 38.4001C2.4 38.5001 2.4 38.5001 2.3 38.6001C2.2 38.6001 2.1 38.7001 2 38.7001C1.9 38.7001 1.8 38.7001 1.7 38.7001C1.6 38.7001 1.5 38.6001 1.4 38.6001C1.3 38.5001 1.3 38.5001 1.2 38.4001C1.1 38.3001 1 38.1001 1 38.0001C1 37.9001 1 37.8001 1 37.7001C1 37.6001 1.1 37.5001 1.1 37.4001C1.2 37.3001 1.2 37.3001 1.3 37.2001C1.4 37.1001 1.5 37.1001 1.6 37.1001C1.7 37.1001 1.9 37.2001 2 37.2001ZM3.7 38.0001L9.9 39.7001C10.1 39.8001 10.3 39.9001 10.5 40.0001C10.7 40.1001 10.8 40.3001 10.9 40.4001C11 40.6001 11.1 40.8001 11.1 41.0001C11.1 41.2001 11.1 41.5001 11 41.8001C11 41.9001 10.9 42.0001 10.9 42.1001C10.9 42.2001 10.8 42.3001 10.7 42.4001L10.2 42.2001C10.2 42.2001 10.1 42.2001 10.1 42.1001V42.0001V41.9001V41.8001C10.2 41.5001 10.2 41.3001 10.1 41.1001C10 40.9001 9.8 40.8001 9.5 40.7001L3.4 39.0001L3.7 38.0001Z"
          fill="#F1CBB6"
        />
        <path
          d="M5.09922 33.6C5.19922 33.3 5.39922 33 5.59922 32.7C5.79922 32.5 6.09922 32.3 6.39922 32.2C6.69922 32.1 6.99922 32 7.39922 32C7.79922 32 8.09922 32.1 8.49922 32.2C8.69922 32.3 8.69922 32.3 8.79922 32.4C8.89922 32.5 8.79922 32.5 8.79922 32.6L7.39922 36.3C7.79922 36.4 8.09922 36.5 8.39922 36.5C8.69922 36.5 8.99922 36.5 9.19922 36.4C9.39922 36.3 9.59922 36.2 9.79922 36C9.99922 35.8 10.0992 35.6 10.1992 35.3C10.2992 35.1 10.2992 34.8 10.3992 34.6C10.3992 34.4 10.3992 34.2 10.3992 34.1C10.3992 33.9 10.3992 33.8 10.2992 33.7C10.2992 33.6 10.2992 33.5 10.2992 33.4C10.2992 33.3 10.3992 33.3 10.4992 33.2L10.9992 33.1C11.0992 33.3 11.1992 33.5 11.1992 33.7C11.1992 33.9 11.2992 34.1 11.2992 34.3C11.2992 34.5 11.2992 34.7 11.1992 35C11.1992 35.2 11.0992 35.4 10.9992 35.6C10.8992 36 10.6992 36.3 10.3992 36.6C10.0992 36.9 9.79922 37.1 9.49922 37.2C9.19922 37.3 8.79922 37.4 8.39922 37.4C7.99922 37.4 7.49922 37.3 7.09922 37.1C6.69922 37 6.39922 36.8 6.09922 36.5C5.79922 36.3 5.59922 36 5.39922 35.6C5.19922 35.2 5.19922 34.9 5.09922 34.6C4.89922 34.4 4.89922 34 5.09922 33.6ZM5.79922 33.8C5.59922 34.3 5.59922 34.7 5.79922 35.1C5.99922 35.5 6.29922 35.8 6.69922 36L7.89922 33C7.69922 32.9 7.49922 32.9 7.19922 32.9C6.99922 32.9 6.79922 32.9 6.59922 33C6.39922 33.1 6.29922 33.2 6.09922 33.3C5.99922 33.4 5.89922 33.6 5.79922 33.8Z"
          fill="#F1CBB6"
        />
        <path
          d="M13.1 30.9001C12.9 31.3001 12.6 31.6001 12.2 31.7001C11.9 31.8001 11.5 31.7001 11 31.5001L7.8 29.8001L7.5 30.4001C7.5 30.5001 7.4 30.5001 7.4 30.5001C7.3 30.5001 7.3 30.5001 7.2 30.5001L6.8 30.3001L7.1 29.4001L5.6 28.4001L5.5 28.3001C5.5 28.2001 5.5 28.2001 5.5 28.1001L5.7 27.6001L7.6 28.6001L8.4 27.1001L9.1 27.5001L8.3 29.0001L11.5 30.7001C11.7 30.8001 11.9 30.8001 12.1 30.8001C12.3 30.7001 12.4 30.6001 12.5 30.5001C12.6 30.4001 12.6 30.3001 12.6 30.2001C12.6 30.1001 12.6 30.0001 12.6 30.0001C12.6 30.0001 12.6 29.9001 12.6 29.8001V29.7001C12.6 29.7001 12.7 29.6001 12.8 29.6001H13.4C13.5 29.8001 13.5 30.1001 13.4 30.4001C13.3 30.7001 13.2 30.7001 13.1 30.9001Z"
          fill="#F1CBB6"
        />
        <path
          d="M11.9004 24.9L13.6004 22.5L14.3004 23L12.6004 25.4L11.9004 24.9Z"
          fill="#F1CBB6"
        />
      </svg>
    </button>
  );
}

export default ButtonNext;
