import { useLocation } from "react-router-dom";
import portfolio from "../data/portfolio.json";
import ButtonNext from "../components/ButtonNext.js";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Page() {
  let location = useLocation();
  const { t } = useTranslation();
  var id = location.pathname.substr(9) - 1;
  console.log(location.state);
  const content = portfolio.projects.map((data) => {
    if (location.pathname.substr(9) === data.id) {
      let dataProject = data;
      console.log(dataProject);
      return (
        <div>
          <div className="page-intro">
            <h1>{dataProject.name}</h1>
            <div>
              <img src={dataProject.photo} alt="project" />
            </div>
          </div>
          <div className="page-description">
            <div className="description-container">
              <div>
                <h2>Production</h2>
                <p>Projet Client</p>
                <p>2021</p>
              </div>
              <div>
                <h2>Role</h2>
                {dataProject.role.map(function (role, index) {
                  return <p key={index}> {role}</p>;
                })}
              </div>
              <div>
                <p>{t("projects." + id + ".description")}</p>
                <p className="link-website">
                  <a href={dataProject.url}>{t("project.see")}</a>
                </p>
              </div>
            </div>
            <div className="capture-container">
              <div className="capture-border capture-top"></div>
              {Object.keys(dataProject.screenshots).map((item, index) => (
                <div className="capture-img">
                  <h2 key={index}>
                    {t("projects." + id + ".screenshots." + item + ".name")}
                  </h2>
                  <img
                    key={index}
                    src={dataProject.screenshots[item].value}
                    alt="screenshots"
                  />
                </div>
              ))}
              <div className="capture-border capture-bottom"></div>
            </div>
            <div className="credit">
              <div>
                <h2>Credits</h2>
                {Object.keys(dataProject.credits).map((item, index) => (
                  <p key={index}>{dataProject.credits[item].name}</p>
                ))}
              </div>
              <div className="credit-name">
                {Object.keys(dataProject.credits).map((item, index) => (
                  <p key={index}>{dataProject.credits[item].value}</p>
                ))}
              </div>
              <div></div>
            </div>
          </div>
          {portfolio.projects.map((data, key) => {
            if (data.id === 1 && dataProject.id === 5) {
              return (
                <div
                  key={key}
                  className="page-next"
                  style={{
                    backgroundImage: `linear-gradient(rgba(248, 236, 224, 0.25), rgba(248, 236, 224, 0.25)), url(${data.photo})`,
                  }}
                >
                  <h2>{t("project.next")}</h2>
                  <h3>{data.name}</h3>
                  <Link
                    to={{
                      pathname: "/project/" + data.id,
                      state: data,
                    }}
                  >
                    <ButtonNext />
                  </Link>
                </div>
              );
            } else if (data.id === dataProject.id + 1) {
              return (
                <div
                  key={key}
                  className="page-next"
                  style={{
                    backgroundImage: `linear-gradient(rgba(248, 236, 224, 0.25), rgba(248, 236, 224, 0.25)), url(${data.photo})`,
                  }}
                >
                  <h2>{t("project.next")}</h2>
                  <h3>{data.name}</h3>
                  <Link
                    to={{
                      pathname: "/project/" + data.id,
                      state: data,
                    }}
                  >
                    <ButtonNext />
                  </Link>
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    }
    return null;
  });
  return (
    <div>
      {location.state === undefined ? (
        <div>{content}</div>
      ) : (
        <div>
          <div className="page-intro">
            <h1>{location.state.name}</h1>
            <div>
              <img src={location.state.photo} alt="project" />
            </div>
          </div>
          <div className="page-description">
            <div className="description-container">
              <div>
                <h2>Production</h2>
                <p>Projet Client</p>
                <p>2021</p>
              </div>
              <div>
                <h2>Role</h2>
                {location.state.role.map(function (role, index) {
                  return <p key={index}> {role}</p>;
                })}
              </div>
              <div>
                <p>{t("projects." + id + ".description")}</p>
                <p className="link-website">
                  <a href={location.state.url}>{t("project.see")}</a>
                </p>
              </div>
            </div>
            <div className="capture-container">
              <div className="capture-border capture-top"></div>
              {Object.keys(location.state.screenshots).map((item, index) => (
                <div className="capture-img">
                  <h2 key={index}>
                    {t("projects." + id + ".screenshots." + item + ".name")}
                  </h2>
                  <img
                    key={index}
                    src={location.state.screenshots[item].value}
                    alt="screenshots"
                  />
                </div>
              ))}
              <div className="capture-border capture-bottom"></div>
            </div>
            <div className="credit">
              <div>
                <h2>Credits</h2>
                {Object.keys(location.state.credits).map((item, index) => (
                  <p key={index}>{location.state.credits[item].name}</p>
                ))}
              </div>
              <div className="credit-name">
                {Object.keys(location.state.credits).map((item, index) => (
                  <p key={index}>{location.state.credits[item].value}</p>
                ))}
              </div>
              <div></div>
            </div>
          </div>

          {portfolio.projects.map((data, key) => {
            if (data.id === 1 && location.state.id === 5) {
              return (
                <div
                  key={key}
                  className="page-next"
                  style={{
                    backgroundImage: `linear-gradient(rgba(248, 236, 224, 0.25), rgba(248, 236, 224, 0.25)), url(${data.photo})`,
                  }}
                >
                  <h2>{t("project.next")}</h2>
                  <h3>{data.name}</h3>
                  <Link
                    to={{
                      pathname: "/project/" + data.id,
                      state: data,
                    }}
                  >
                    <ButtonNext />
                  </Link>
                </div>
              );
            } else if (data.id === location.state.id + 1) {
              return (
                <div
                  key={key}
                  className="page-next"
                  style={{
                    backgroundImage: `linear-gradient(rgba(248, 236, 224, 0.25), rgba(248, 236, 224, 0.25)), url(${data.photo})`,
                  }}
                >
                  <h2>{t("project.next")}</h2>
                  <h3>{data.name}</h3>
                  <Link
                    to={{
                      pathname: "/project/" + data.id,
                      state: data,
                    }}
                  >
                    <ButtonNext />
                  </Link>
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}

export default Page;
