import Intro from "../components/Intro";
import Project from "../components/Project";
import About from "../components/About";
import Skills from "../components/Skills";
import Contact from "../components/Contact";

function Home() {
  return (
    <div>
      <Intro />
      <Project />
      <About />
      <Skills />
      <Contact />
    </div>
  );
}

export default Home;
