import React, { useState } from "react";
import portfolio from "../data/portfolio.json";
import echappe from "../images/echappe.svg";
import skills_arrow from "../images/skills_arrow.svg";
import { useTranslation } from "react-i18next";

function SkillsContainer(props) {
  const [activeId, setactiveId] = useState(null);

  function handleToggle(id) {
    if (activeId === id) {
      // Si l'élément actuel est cliqué de nouveau, désactivez-le
      setactiveId(null);
    } else {
      // Sinon, activez-le
      setactiveId(id);
    }
  }

  const { t } = useTranslation();

  return (
    <div className="skills-item">
      {props.data.map((val, index) => (
        <div
          className={
            activeId === val.id
              ? "skills-container"
              : "skills-container active-container"
          }
          key={index}
        >
          <div
            className={
              activeId === val.id
                ? "skills-name-container"
                : "skills-name-container active"
            }
            onClick={() => handleToggle(val.id)}
          >
            <h3 className="skills-name">
              {t("skills." + val.id + ".name")}
            </h3>
            <img
              className={
                activeId === val.id ? "echappe" : "skills_arrow"
              }
              src={
                activeId === val.id ? echappe : skills_arrow
              }
              alt={
                activeId === val.id ? "echappe" : "arrow"
              }
            />
          </div>
          <ul
            className={
              activeId === val.id
                ? "skills-infos"
                : "skills-infos onActive"
            }
          >
            <li className="skills-description">
              {t("skills." + val.id + ".description")}
            </li>
            <li className="skills-tools">
              <p>
                {val.outils.map(function (outils, index) {
                  return <span key={index}> {outils} |</span>;
                })}
              </p>
            </li>
            <li className="skills-date">{t("skill.date")}</li>
          </ul>
        </div>
      ))}
    </div>
  );
}

function Skills() {
  const { t } = useTranslation();
  return (
    <div className="container-bg bg-skills d-flex align-items-center justify-content-center flex-column">
      <div className="skills-flex d-flex align-items-center justify-content-around w-100">
        <div className="skills-title-container">
          <h2 className="skills-title">{t("skill.title")}</h2>
        </div>
        <SkillsContainer data={portfolio.skills} />
      </div>
    </div>
  );
}

export default Skills;
