import photo from "../images/photo.jpg";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();
  return (
    <div className="container-bg bg-about" id="about">
      <div className="container-about">
        <h2>About</h2>
        <div className="container-photo">
          <img src={photo} alt="profil" width="100%" />
          <p>エラーは人間です</p>
          <p id="proverbe">{t("about.proverbe")}</p>
        </div>
        <div className="about-text">
          <p>
            {t("about.pargraphe1")}
            <br />
            <br />
            {t("about.pargraphe2")}
          </p>
          <p>
            {t("about.pargraphe3")}
            <br />
            <br />
            {t("about.pargraphe4")}
          </p>
        </div>
        <div className="button-container">
          <a
            href="https://lenaclavier.com/cv_lena_clavier.pdf"
            className="button-cv"
            target="_blank"
            without rel="noreferrer"
          >
            {t("about.button")}
          </a>
          <svg
            className="arrow"
            width="60"
            height="15"
            viewBox="0 0 69 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 6.5C0.947715 6.5 0.5 6.94772 0.5 7.5C0.5 8.05228 0.947715 8.5 1.5 8.5V6.5ZM68.2071 8.20711C68.5976 7.81658 68.5976 7.18342 68.2071 6.79289L61.8431 0.428932C61.4526 0.0384079 60.8195 0.0384079 60.4289 0.428932C60.0384 0.819456 60.0384 1.45262 60.4289 1.84315L66.0858 7.5L60.4289 13.1569C60.0384 13.5474 60.0384 14.1805 60.4289 14.5711C60.8195 14.9616 61.4526 14.9616 61.8431 14.5711L68.2071 8.20711ZM1.5 8.5H67.5V6.5H1.5V8.5Z"
              fill="#0065F2"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default About;
