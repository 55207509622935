import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Page from "./pages/Page";
import Home from "./pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Fragment } from "react";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <Fragment>
        <div className="App">
          <Header />
          <main>
            <Switch>
              <Route path="/project/:id">
                <ScrollToTop />
                <Page />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </main>
          <Footer />
        </div>
      </Fragment>
    </Router>
  );
}

export default App;
