function ButtonSend() {
  return (
    <button aria-label="Send" className="border-0">
      <svg
        width="120"
        height="120"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="button-svg svg-send"
      >
        <path
          d="M41.1517 40.596C41.3691 40.8133 41.6951 41.0305 41.9125 41.1391C42.1299 41.2477 42.456 41.3564 42.6734 41.3564C42.8908 41.3564 43.1082 41.3564 43.3256 41.2477C43.543 41.1391 43.7604 41.0305 43.9778 40.8133C44.1951 40.596 44.3038 40.4874 44.4125 40.2702C44.5212 40.053 44.6299 39.9443 44.6299 39.7271C44.7386 39.6185 44.7386 39.4013 44.7386 39.2926C44.7386 39.184 44.8473 39.0754 44.956 38.9668C45.0647 38.8582 45.1734 38.8582 45.2821 38.8582L46.043 38.9668C46.043 39.2926 46.043 39.5099 45.9343 39.8357C45.8256 40.1616 45.7169 40.3788 45.6082 40.596C45.4995 40.8133 45.3908 41.0305 45.1734 41.2477C44.956 41.465 44.8473 41.6822 44.6299 41.7908C44.3038 42.1167 43.8691 42.3339 43.4343 42.5511C42.9995 42.7684 42.5647 42.7684 42.1299 42.7684C41.6951 42.7684 41.2604 42.6598 40.8256 42.4425C40.3908 42.2253 39.956 41.8994 39.5212 41.465C39.1951 41.1391 38.9777 40.7047 38.7604 40.3788C38.543 39.9443 38.543 39.5099 38.543 39.184C38.543 38.7496 38.6517 38.3151 38.7604 37.8806C38.8691 37.4461 39.1951 37.1203 39.6299 36.6858C39.956 36.36 40.2821 36.1427 40.6082 36.0341C40.9343 35.9255 41.3691 35.8169 41.6951 35.8169C42.0212 35.8169 42.456 35.9255 42.7821 36.1427C43.1082 36.36 43.543 36.5772 43.8691 37.0117C44.0864 37.2289 44.0864 37.3375 44.1951 37.4461C44.1951 37.5548 44.1951 37.6634 44.0864 37.772L41.1517 40.596ZM42.7821 37.6634C42.6734 37.5548 42.456 37.3375 42.3473 37.2289C42.1299 37.1203 42.0212 37.1203 41.8038 37.0117C41.5864 37.0117 41.4778 37.0117 41.2604 37.1203C41.043 37.2289 40.8256 37.3375 40.7169 37.4461C40.3908 37.772 40.1734 38.0979 40.1734 38.5323C40.1734 38.9668 40.2821 39.2926 40.6082 39.7271L42.7821 37.6634Z"
          fill="#0065F2"
        />
        <path
          d="M46.5868 32.5583C46.5868 32.3411 46.6955 32.1238 46.8042 32.0152C46.9129 31.9066 46.9129 31.6894 47.0216 31.4721C47.1303 31.2549 47.239 31.1463 47.3477 31.0377C47.4564 30.929 47.6737 30.7118 47.8911 30.6032C48.2172 30.386 48.5433 30.2773 48.8694 30.2773C49.1955 30.2773 49.5216 30.2773 49.739 30.386C50.065 30.4946 50.2824 30.6032 50.6085 30.8204C50.9346 31.0377 51.152 31.3635 51.2607 31.6894L53.652 35.3823L52.239 36.1427L49.8477 32.4497C49.6303 32.1238 49.4129 31.9066 49.0868 31.798C48.7607 31.6894 48.4346 31.798 48.1085 32.0152C47.8911 32.1238 47.6737 32.3411 47.565 32.6669C47.4564 32.8842 47.3477 33.21 47.239 33.5359L49.9564 37.7719L48.5433 38.6409L44.8477 32.6669L45.7172 32.1238C45.9346 32.0152 46.0433 32.0152 46.2607 32.1238L46.5868 32.5583Z"
          fill="#0065F2"
        />
        <path
          d="M58.9788 25.498V32.9926L57.5657 33.5357L52.457 28.1049L53.7614 27.5618C53.8701 27.5618 53.9788 27.4532 54.0875 27.5618C54.1962 27.5618 54.3049 27.6704 54.3049 27.6704L56.9136 30.603C57.0223 30.8203 57.2396 30.9289 57.3483 31.1461C57.457 31.3634 57.5657 31.472 57.6744 31.6892C57.6744 31.472 57.5657 31.2547 57.5657 31.0375C57.5657 30.8203 57.5657 30.603 57.5657 30.3858L57.457 26.4756C57.457 26.367 57.457 26.2584 57.5657 26.2584C57.5657 26.1497 57.6744 26.1497 57.7831 26.0411L58.9788 25.498Z"
          fill="#0065F2"
        />
        <path
          d="M64.7397 23.9775C65.2832 23.8688 65.718 23.8688 66.1528 23.9775C66.5875 24.0861 67.0223 24.1947 67.3484 24.4119C67.6745 24.6292 68.0006 24.955 68.218 25.3895C68.4354 25.8239 68.6528 26.2584 68.7615 26.8015C68.8702 27.3446 68.8702 27.8877 68.8702 28.3221C68.8702 28.7566 68.6528 29.1911 68.4354 29.5169C68.218 29.8428 67.8919 30.1686 67.5658 30.3859C67.2397 30.6031 66.6962 30.8203 66.2615 30.9289C65.718 31.0376 65.2832 31.0376 64.8484 30.9289C64.4136 30.8203 63.9789 30.7117 63.6528 30.4945C63.3267 30.2772 63.0006 29.9514 62.7832 29.5169C62.5658 29.0825 62.3484 28.648 62.2397 28.1049C62.131 27.5618 62.131 27.0187 62.2397 26.5843C62.3484 26.1498 62.4571 25.7153 62.6745 25.3895C62.8919 25.0636 63.218 24.7378 63.6528 24.5205C63.7615 24.3033 64.1962 24.0861 64.7397 23.9775ZM65.9354 29.7342C66.4789 29.6255 66.9136 29.2997 67.131 28.8652C67.3484 28.4308 67.3484 27.8877 67.2397 27.1273C67.131 26.367 66.8049 25.8239 66.4789 25.4981C66.1528 25.1722 65.718 25.0636 65.0658 25.1722C64.5223 25.2809 64.0876 25.6067 63.8702 26.0412C63.6528 26.4756 63.6528 27.0187 63.7615 27.7791C63.8702 28.5394 64.1962 29.0825 64.5223 29.4083C64.8484 29.7342 65.2832 29.8428 65.9354 29.7342Z"
          fill="#0065F2"
        />
        <path
          d="M77.6739 23L73.9783 31.9066C73.9783 32.0152 73.8696 32.1238 73.7609 32.1238C73.6522 32.1238 73.5435 32.2324 73.4348 32.2324H72.2391L73.4348 29.4084L70.5 23H72.0217C72.1304 23 72.2391 23 72.3478 23.1086C72.4565 23.2172 72.4565 23.2172 72.5652 23.3259L74.087 26.9102C74.087 27.0188 74.1956 27.1274 74.1956 27.2361C74.1956 27.3447 74.3043 27.4533 74.3043 27.5619C74.3043 27.4533 74.413 27.3447 74.413 27.2361C74.413 27.1274 74.5217 27.0188 74.5217 26.9102L75.8261 23.3259C75.8261 23.2172 75.9348 23.1086 76.0435 23.1086C76.1522 23 76.2609 23 76.3696 23H77.6739Z"
          fill="#0065F2"
        />
        <path
          d="M81.1528 27.1275C81.1528 27.4534 81.1528 27.7792 81.1528 28.1051C81.1528 28.4309 81.2615 28.6482 81.4789 28.7568C81.5875 28.974 81.8049 29.0826 82.0223 29.1912C82.2397 29.2999 82.4571 29.4085 82.7832 29.4085C83.0006 29.4085 83.3267 29.4085 83.4354 29.4085C83.6528 29.4085 83.8701 29.2999 83.9788 29.2999C84.0875 29.2999 84.3049 29.1912 84.4136 29.1912C84.5223 29.1912 84.631 29.0826 84.7397 29.1912C84.8484 29.1912 84.9571 29.2999 85.0658 29.4085L85.5006 30.0602C85.2832 30.2774 85.0658 30.386 84.8484 30.4946C84.631 30.6033 84.3049 30.7119 84.0875 30.7119C83.8702 30.7119 83.5441 30.8205 83.3267 30.8205C83.1093 30.8205 82.7832 30.8205 82.5658 30.7119C82.131 30.6033 81.6962 30.4946 81.2615 30.2774C80.8267 30.0602 80.6093 29.7343 80.2832 29.4085C80.0658 29.0826 79.8484 28.6482 79.7397 28.1051C79.631 27.6706 79.631 27.1275 79.7397 26.4758C79.8484 26.0413 79.9571 25.6069 80.1745 25.1724C80.3919 24.7379 80.6093 24.4121 81.0441 24.1949C81.3702 23.9776 81.8049 23.7604 82.2397 23.6518C82.6745 23.5432 83.1093 23.5432 83.6528 23.6518C84.0875 23.7604 84.5223 23.869 84.8484 24.0862C85.1745 24.3035 85.5006 24.5207 85.718 24.8466C85.9354 25.1724 86.0441 25.4983 86.1528 25.9327C86.2614 26.3672 86.2614 26.8017 86.1528 27.2361C86.1528 27.4534 86.0441 27.6706 86.0441 27.7792C86.0441 27.8878 85.8267 27.8878 85.718 27.8878L81.1528 27.1275ZM84.4136 26.5844C84.4136 26.3672 84.4136 26.15 84.4136 25.9327C84.4136 25.7155 84.3049 25.6069 84.1962 25.3896C84.0875 25.281 83.9788 25.0638 83.7615 24.9552C83.5441 24.8466 83.3267 24.7379 83.1093 24.7379C82.6745 24.6293 82.2397 24.7379 81.9136 24.9552C81.5875 25.1724 81.3702 25.4983 81.2615 26.0413L84.4136 26.5844Z"
          fill="#0065F2"
        />
        <path
          d="M94.088 27.3444C94.088 27.453 93.9793 27.5617 93.8706 27.6703C93.7619 27.7789 93.7619 27.8875 93.6532 27.8875L89.1967 30.9288L92.4575 32.015L92.0228 33.2097L86.9141 31.4719L87.1315 30.8202C87.1315 30.7116 87.2401 30.7116 87.2401 30.6029C87.3488 30.4943 87.3488 30.3857 87.4575 30.3857L92.0228 27.3444L88.8706 26.2583L89.3054 25.0635L94.3054 26.6927L94.088 27.3444Z"
          fill="#0065F2"
        />
        <path
          d="M100.609 32.124L103.543 33.9705L102.782 35.1653L99.8477 33.3188L100.609 32.124Z"
          fill="#0065F2"
        />
        <path
          d="M109.631 41.2474C109.414 41.4647 109.196 41.7905 109.087 42.0077C108.979 42.225 108.87 42.5508 108.87 42.7681C108.87 42.9853 108.87 43.2025 108.979 43.4198C109.087 43.637 109.196 43.8542 109.414 44.0715C109.631 44.2887 109.74 44.3973 109.957 44.5059C110.174 44.6146 110.283 44.7232 110.501 44.7232C110.609 44.8318 110.827 44.8318 110.935 44.8318C111.044 44.8318 111.153 44.9404 111.261 45.049C111.37 45.1576 111.37 45.2663 111.37 45.3749L111.261 46.1352C110.935 46.1352 110.718 46.1352 110.392 46.0266C110.174 45.918 109.848 45.8093 109.631 45.7007C109.414 45.5921 109.196 45.4835 108.979 45.2663C108.761 45.049 108.544 44.9404 108.435 44.7232C108.109 44.3973 107.892 43.9629 107.674 43.5284C107.457 43.0939 107.457 42.6594 107.457 42.225C107.457 41.7905 107.566 41.356 107.783 40.9216C108.001 40.4871 108.327 40.0526 108.761 39.6182C109.087 39.2923 109.522 39.0751 109.848 38.8579C110.283 38.6406 110.718 38.6406 111.044 38.6406C111.37 38.6406 111.914 38.7492 112.348 38.9665C112.783 39.1837 113.109 39.4009 113.544 39.8354C113.87 40.1613 114.087 40.4871 114.196 40.813C114.305 41.1388 114.414 41.5733 114.414 41.8991C114.414 42.225 114.305 42.6595 114.087 42.9853C113.87 43.3112 113.653 43.7456 113.218 44.0715C113.001 44.2887 112.892 44.2887 112.783 44.3973C112.674 44.3973 112.566 44.3973 112.457 44.2887L109.631 41.2474ZM112.566 42.8767C112.674 42.7681 112.892 42.5508 113.001 42.4422C113.109 42.225 113.109 42.1164 113.218 41.8991C113.327 41.6819 113.218 41.5733 113.109 41.356C113.001 41.1388 112.892 40.9216 112.783 40.813C112.457 40.4871 112.131 40.2699 111.696 40.2699C111.261 40.2699 110.935 40.3785 110.501 40.7043L112.566 42.8767Z"
          fill="#0065F2"
        />
        <path
          d="M117.674 46.6783C117.892 46.6783 118.109 46.7869 118.218 46.8955C118.435 47.0042 118.544 47.0042 118.761 47.1128C118.87 47.2214 119.087 47.33 119.196 47.4386C119.305 47.5472 119.522 47.7645 119.631 47.9817C119.848 48.3076 119.957 48.6334 119.957 48.9593C119.957 49.2851 119.957 49.611 119.848 49.8282C119.739 50.0454 119.631 50.3713 119.413 50.6971C119.196 51.023 118.87 51.2402 118.544 51.3488L114.848 53.7384L113.979 52.3264L117.674 49.9368C118 49.7196 118.218 49.5023 118.326 49.1765C118.435 48.8506 118.326 48.5248 118.109 48.1989C118 47.9817 117.783 47.7645 117.457 47.6559C117.131 47.5472 116.913 47.4386 116.587 47.33L112.348 50.0454L111.479 48.6334L117.348 44.9404L117.892 45.8094C118 46.0266 118 46.1352 117.892 46.3524L117.674 46.6783Z"
          fill="#0065F2"
        />
        <path
          d="M124.739 59.061H117.239L116.695 57.6489L122.13 52.5439L122.674 53.8473C122.674 53.956 122.782 54.0646 122.674 54.1732C122.674 54.2818 122.565 54.3904 122.565 54.3904L119.63 56.9972C119.413 57.1059 119.304 57.3231 119.087 57.4317C118.869 57.5403 118.761 57.6489 118.543 57.7576C118.761 57.7576 118.978 57.6489 119.195 57.6489C119.413 57.6489 119.63 57.6489 119.847 57.6489L123.761 57.5403C123.869 57.5403 123.978 57.5403 123.978 57.6489C124.087 57.6489 124.087 57.7576 124.195 57.8662L124.739 59.061Z"
          fill="#0065F2"
        />
        <path
          d="M126.261 64.8177C126.369 65.3607 126.369 65.7952 126.261 66.2297C126.152 66.6642 126.043 67.0986 125.826 67.4245C125.609 67.7503 125.282 68.0762 124.848 68.2934C124.413 68.5106 123.978 68.7279 123.435 68.8365C122.891 68.9451 122.348 68.9451 121.913 68.8365C121.478 68.7279 121.043 68.6193 120.717 68.402C120.391 68.1848 120.065 67.8589 119.848 67.5331C119.63 67.2072 119.413 66.6642 119.304 66.2297C119.195 65.6866 119.195 65.2521 119.304 64.8177C119.413 64.3832 119.522 63.9487 119.848 63.6229C120.174 63.297 120.391 62.9712 120.826 62.7539C121.261 62.5367 121.695 62.3195 122.239 62.2109C122.782 62.1022 123.326 62.1022 123.761 62.2109C124.195 62.3195 124.63 62.4281 124.956 62.6453C125.282 62.8626 125.609 63.1884 125.826 63.6229C125.935 63.8401 126.152 64.3832 126.261 64.8177ZM120.5 66.0125C120.609 66.5555 120.935 66.99 121.369 67.2072C121.804 67.4245 122.348 67.4245 123.109 67.3159C123.869 67.2072 124.413 66.8814 124.739 66.5555C125.065 66.2297 125.174 65.7952 125.065 65.1435C124.956 64.6004 124.63 64.166 124.195 63.9487C123.761 63.7315 123.217 63.7315 122.456 63.8401C121.695 63.9487 121.152 64.2746 120.826 64.6004C120.5 64.9263 120.391 65.3607 120.5 66.0125Z"
          fill="#0065F2"
        />
        <path
          d="M127.13 77.6343L118.217 74.05C118.108 74.05 117.999 73.9413 117.999 73.8327C117.999 73.7241 117.891 73.6155 117.891 73.5069V72.3121L120.717 73.5069L127.13 70.5742V72.0949C127.13 72.2035 127.13 72.3121 127.021 72.4207C126.912 72.5293 126.912 72.5293 126.804 72.6379L123.217 74.1586C123.108 74.1586 122.999 74.2672 122.891 74.2672C122.782 74.2672 122.673 74.3758 122.565 74.3758C122.673 74.3758 122.782 74.4844 122.891 74.4844C122.999 74.4844 123.108 74.593 123.217 74.593L126.804 75.8965C126.912 75.8965 127.021 76.0051 127.021 76.1137C127.13 76.2223 127.13 76.3309 127.13 76.4395V77.6343Z"
          fill="#0065F2"
        />
        <path
          d="M123.108 81.1102C122.782 81.1102 122.456 81.1102 122.13 81.1102C121.803 81.1102 121.586 81.2188 121.477 81.436C121.26 81.5446 121.151 81.7619 121.043 81.9791C120.934 82.1964 120.825 82.4136 120.825 82.7394C120.825 82.9567 120.825 83.2825 120.825 83.4998C120.825 83.717 120.934 83.9342 120.934 84.0428C120.934 84.1515 121.043 84.3687 121.043 84.4773C121.043 84.5859 121.151 84.6945 121.043 84.8032C121.043 84.9118 120.934 85.0204 120.825 85.129L120.173 85.4549C119.956 85.2376 119.847 85.0204 119.738 84.8032C119.629 84.5859 119.521 84.2601 119.521 84.0428C119.521 83.8256 119.412 83.4998 119.412 83.2825C119.412 83.0653 119.412 82.7394 119.521 82.5222C119.629 82.0877 119.738 81.6533 119.956 81.2188C120.173 80.7843 120.499 80.5671 120.825 80.2412C121.151 80.024 121.586 79.8068 122.13 79.6982C122.564 79.5895 123.108 79.5895 123.76 79.6982C124.195 79.8068 124.63 79.9154 125.064 80.1326C125.499 80.3499 125.825 80.6757 126.043 81.0016C126.26 81.3274 126.477 81.7619 126.586 82.1963C126.695 82.6308 126.695 83.0653 126.586 83.6084C126.477 84.0428 126.369 84.4773 126.151 84.8032C125.934 85.129 125.716 85.4549 125.39 85.6721C125.064 85.8893 124.738 85.9979 124.303 86.1066C123.869 86.2152 123.434 86.2152 122.999 86.1066C122.782 86.1066 122.564 85.9979 122.456 85.9979C122.347 85.8893 122.347 85.7807 122.347 85.6721L123.108 81.1102ZM123.543 84.4773C123.76 84.4773 123.977 84.4773 124.195 84.4773C124.412 84.4773 124.521 84.3687 124.738 84.2601C124.847 84.1515 125.064 84.0428 125.173 83.8256C125.282 83.6084 125.39 83.3911 125.39 83.1739C125.499 82.7394 125.39 82.305 125.173 81.9791C124.956 81.6533 124.63 81.436 124.086 81.3274L123.543 84.4773Z"
          fill="#0065F2"
        />
        <path
          d="M122.891 94.144C122.782 94.144 122.674 94.0354 122.565 93.9268C122.456 93.8182 122.348 93.8182 122.348 93.7096L119.304 89.1477L118.217 92.4062L117.021 91.9717L118.761 86.8667L119.413 87.0839C119.521 87.0839 119.521 87.1925 119.63 87.1925C119.739 87.3012 119.848 87.3012 119.848 87.4098L122.891 91.9717L123.978 88.8218L125.174 89.2563L123.435 94.2526L122.891 94.144Z"
          fill="#0065F2"
        />
        <path
          d="M118.001 100.552L116.153 103.485L114.957 102.725L116.805 99.792L118.001 100.552Z"
          fill="#0065F2"
        />
        <path
          d="M108.979 109.676C108.762 109.459 108.436 109.242 108.218 109.133C108.001 109.025 107.675 108.916 107.457 108.916C107.24 108.916 107.023 108.916 106.805 109.025C106.588 109.133 106.371 109.242 106.153 109.459C105.936 109.676 105.827 109.785 105.718 110.002C105.61 110.219 105.501 110.328 105.501 110.545C105.392 110.654 105.392 110.871 105.392 110.98C105.392 111.088 105.284 111.197 105.175 111.305C105.066 111.414 104.957 111.414 104.849 111.414L104.088 111.305C104.088 110.98 104.088 110.762 104.197 110.437C104.305 110.111 104.414 109.893 104.523 109.676C104.631 109.459 104.74 109.242 104.957 109.025C105.175 108.807 105.284 108.59 105.501 108.481C105.827 108.156 106.262 107.938 106.697 107.721C107.131 107.504 107.566 107.504 108.001 107.504C108.436 107.504 108.871 107.613 109.305 107.83C109.74 108.047 110.175 108.373 110.61 108.807C110.936 109.133 111.153 109.568 111.371 109.893C111.588 110.219 111.588 110.762 111.588 111.088C111.588 111.523 111.479 111.957 111.262 112.392C111.044 112.826 110.827 113.152 110.392 113.586C110.066 113.912 109.74 114.13 109.305 114.238C108.979 114.347 108.544 114.455 108.218 114.455C107.892 114.455 107.457 114.347 107.131 114.13C106.805 113.912 106.371 113.695 106.044 113.261C105.827 113.043 105.827 112.935 105.718 112.826C105.718 112.718 105.718 112.609 105.827 112.5L108.979 109.676ZM107.24 112.5C107.349 112.609 107.566 112.826 107.675 112.935C107.892 113.043 108.001 113.043 108.218 113.152C108.436 113.152 108.544 113.152 108.762 113.043C108.979 112.935 109.197 112.826 109.305 112.718C109.631 112.392 109.849 112.066 109.849 111.631C109.849 111.197 109.74 110.871 109.414 110.437L107.24 112.5Z"
          fill="#0065F2"
        />
        <path
          d="M103.434 117.714C103.434 117.931 103.326 118.148 103.217 118.257C103.108 118.474 103.108 118.583 103 118.8C102.891 118.909 102.782 119.126 102.673 119.235C102.565 119.343 102.347 119.56 102.13 119.669C101.804 119.886 101.478 119.995 101.152 119.995C100.826 119.995 100.5 119.995 100.282 119.886C99.9561 119.778 99.7387 119.669 99.4126 119.452C99.1952 119.235 98.9778 118.909 98.7604 118.583L96.3691 114.89L97.7822 114.021L100.173 117.714C100.391 118.04 100.608 118.257 100.934 118.366C101.26 118.474 101.587 118.366 101.913 118.148C102.13 118.04 102.347 117.823 102.456 117.605C102.565 117.388 102.673 117.062 102.782 116.736L100.065 112.5L101.478 111.631L105.173 117.497L104.304 118.04C104.087 118.148 103.978 118.148 103.76 118.04L103.434 117.714Z"
          fill="#0065F2"
        />
        <path
          d="M91.043 124.774V117.279L92.456 116.736L97.5647 122.167L96.2604 122.71C96.1517 122.71 96.043 122.819 95.9343 122.71C95.8256 122.71 95.7169 122.602 95.7169 122.602L93.1082 119.669C92.9995 119.452 92.7821 119.343 92.6734 119.126C92.5647 118.909 92.456 118.8 92.3473 118.583C92.3473 118.8 92.456 119.017 92.456 119.235C92.456 119.452 92.456 119.669 92.456 119.886L92.5647 123.796C92.5647 123.905 92.5647 124.014 92.456 124.014C92.456 124.122 92.3473 124.122 92.2386 124.231L91.043 124.774Z"
          fill="#0065F2"
        />
        <path
          d="M85.283 126.186C84.7395 126.295 84.3047 126.295 83.8699 126.186C83.4351 126.078 83.0003 125.969 82.6743 125.752C82.3482 125.535 82.0221 125.209 81.8047 124.774C81.5873 124.34 81.3699 123.905 81.2612 123.362C81.1525 122.819 81.1525 122.276 81.2612 121.842C81.3699 121.407 81.4786 120.973 81.696 120.647C81.9134 120.321 82.2395 119.995 82.5656 119.778C82.8917 119.561 83.4351 119.343 83.8699 119.235C84.4134 119.126 84.8482 119.126 85.283 119.235C85.7177 119.343 86.1525 119.452 86.4786 119.778C86.8047 119.995 87.1308 120.321 87.3482 120.755C87.5656 121.19 87.783 121.624 87.8917 122.167C88.0004 122.711 88.0004 123.254 87.8917 123.688C87.783 124.123 87.6743 124.557 87.4569 124.883C87.2395 125.209 86.9134 125.535 86.4786 125.752C86.2612 125.969 85.8264 126.078 85.283 126.186ZM84.196 120.43C83.6525 120.538 83.2177 120.864 83.0003 121.299C82.783 121.733 82.783 122.276 82.8916 123.036C83.0003 123.797 83.3264 124.34 83.6525 124.666C83.9786 124.991 84.4134 125.1 85.0656 124.991C85.609 124.883 86.0438 124.557 86.2612 124.123C86.4786 123.688 86.4786 123.145 86.3699 122.385C86.2612 121.624 85.9351 121.081 85.609 120.755C85.1743 120.43 84.7395 120.321 84.196 120.43Z"
          fill="#0065F2"
        />
        <path
          d="M72.3477 127.164L75.9346 118.257C75.9346 118.148 76.0433 118.04 76.152 118.04C76.2607 117.931 76.3694 117.931 76.4781 117.931H77.6737L76.3694 120.755L79.3042 127.164H77.7824C77.6737 127.164 77.565 127.164 77.4564 127.055C77.3477 126.946 77.3477 126.946 77.239 126.838L75.7172 123.253C75.7172 123.145 75.6085 123.036 75.6085 122.928C75.6085 122.819 75.4998 122.71 75.4998 122.602C75.4998 122.71 75.3911 122.819 75.3911 122.928C75.3911 123.036 75.2824 123.145 75.2824 123.253L73.9781 126.838C73.9781 126.946 73.8694 127.055 73.7607 127.055C73.652 127.164 73.5433 127.164 73.4346 127.164H72.3477Z"
          fill="#0065F2"
        />
        <path
          d="M68.978 123.036C68.978 122.71 68.978 122.385 68.978 122.059C68.978 121.733 68.8693 121.516 68.6519 121.407C68.5432 121.19 68.3258 121.081 68.1084 120.973C67.8911 120.864 67.6737 120.755 67.3476 120.755C67.1302 120.755 66.8041 120.755 66.5867 120.755C66.3693 120.755 66.1519 120.864 66.0432 120.864C65.9345 120.864 65.7171 120.973 65.6084 120.973C65.4997 120.973 65.3911 121.081 65.2824 120.973C65.1737 120.973 65.065 120.864 64.9563 120.755L64.6302 120.104C64.8476 119.886 65.065 119.778 65.2824 119.669C65.4997 119.56 65.8258 119.452 66.0432 119.452C66.2606 119.452 66.5867 119.343 66.8041 119.343C67.0215 119.343 67.3476 119.343 67.565 119.452C67.9997 119.56 68.4345 119.669 68.8693 119.886C69.3041 120.104 69.5215 120.429 69.8476 120.755C70.1737 121.081 70.2824 121.516 70.3911 122.059C70.4997 122.493 70.4997 123.036 70.3911 123.688C70.2824 124.122 70.1737 124.557 69.9563 124.991C69.7389 125.426 69.4128 125.752 69.0867 125.969C68.7606 126.186 68.3258 126.403 67.8911 126.512C67.4563 126.621 67.0215 126.621 66.478 126.512C66.0432 126.403 65.6084 126.295 65.2824 126.078C64.9563 125.86 64.6302 125.643 64.4128 125.317C64.1954 124.991 64.0867 124.666 63.978 124.231C63.8693 123.797 63.8693 123.362 63.978 122.928C63.978 122.71 64.0867 122.493 64.0867 122.385C64.0867 122.276 64.3041 122.276 64.4128 122.276L68.978 123.036ZM65.6084 123.579C65.6084 123.797 65.6084 124.014 65.6084 124.231C65.6084 124.448 65.7171 124.557 65.8258 124.774C65.9345 124.883 66.0432 125.1 66.2606 125.209C66.478 125.317 66.6954 125.426 66.9128 125.426C67.3476 125.534 67.7824 125.426 68.1084 125.209C68.4345 124.991 68.6519 124.665 68.8693 124.122L65.6084 123.579Z"
          fill="#0065F2"
        />
        <path
          d="M55.9349 122.819C55.9349 122.71 56.0436 122.602 56.1523 122.493C56.261 122.384 56.261 122.276 56.3697 122.276L60.9349 119.235L57.674 118.148L58.1088 116.954L63.2175 118.691L63.0001 119.343C63.0001 119.452 62.8914 119.452 62.8914 119.56C62.7827 119.669 62.7827 119.778 62.674 119.778L58.1088 122.819L61.261 123.905L60.8262 125.1L55.8262 123.362L55.9349 122.819Z"
          fill="#0065F2"
        />
        <path
          d="M49.5207 117.931L46.5859 115.976L47.3468 114.781L50.2816 116.736L49.5207 117.931Z"
          fill="#0065F2"
        />
        <path
          d="M40.4994 108.916C40.7168 108.699 40.9342 108.373 41.0429 108.156C41.1516 107.939 41.2603 107.613 41.2603 107.395C41.2603 107.178 41.2603 106.961 41.1516 106.744C41.0429 106.527 40.9342 106.309 40.7168 106.092C40.4994 105.875 40.3907 105.766 40.1733 105.658C39.9559 105.549 39.8472 105.44 39.6298 105.44C39.5211 105.332 39.3038 105.332 39.1951 105.332C39.0864 105.332 38.9777 105.223 38.869 105.114C38.7603 105.006 38.7603 104.897 38.7603 104.789L38.869 104.028C39.1951 104.028 39.4124 104.028 39.7385 104.137C39.9559 104.246 40.282 104.354 40.4994 104.463C40.7168 104.571 40.9342 104.68 41.1516 104.897C41.369 105.114 41.5864 105.223 41.6951 105.44C42.0211 105.766 42.2385 106.201 42.4559 106.635C42.5646 107.07 42.6733 107.504 42.6733 107.939C42.6733 108.373 42.5646 108.807 42.3472 109.242C42.1298 109.676 41.8038 110.111 41.369 110.545C41.0429 110.871 40.6081 111.088 40.282 111.306C39.8472 111.523 39.4124 111.523 39.0864 111.523C38.7603 111.523 38.2168 111.414 37.782 111.197C37.3472 110.98 37.0211 110.763 36.5864 110.328C36.2603 110.002 36.0429 109.676 35.9342 109.242C35.8255 108.916 35.7168 108.482 35.7168 108.156C35.7168 107.83 35.8255 107.395 36.0429 107.07C36.2603 106.744 36.4777 106.309 36.9125 105.983C37.1298 105.766 37.2385 105.766 37.3472 105.658C37.4559 105.658 37.5646 105.658 37.6733 105.766L40.4994 108.916ZM37.5646 107.178C37.4559 107.287 37.2385 107.504 37.1298 107.613C37.0211 107.83 37.0211 108.047 37.0211 108.264C37.0211 108.482 37.0211 108.59 37.1298 108.807C37.2385 109.025 37.3472 109.242 37.4559 109.351C37.782 109.676 38.1081 109.894 38.5429 109.894C38.9777 109.894 39.3038 109.785 39.7385 109.459L37.5646 107.178Z"
          fill="#0065F2"
        />
        <path
          d="M32.3471 103.377C32.1297 103.377 31.9123 103.268 31.8036 103.159C31.5862 103.051 31.4775 103.051 31.2601 102.942C31.0427 102.833 30.934 102.725 30.8253 102.616C30.7166 102.508 30.4992 102.29 30.3905 102.073C30.1731 101.747 30.0645 101.421 30.0645 101.096C30.0645 100.77 30.0645 100.444 30.1731 100.227C30.2818 99.9008 30.3905 99.6835 30.6079 99.3577C30.8253 99.1404 31.1514 98.9232 31.4775 98.706L35.1731 96.3164L36.0427 97.7284L32.3471 100.118C32.021 100.335 31.8036 100.552 31.6949 100.878C31.5862 101.204 31.6949 101.53 31.9123 101.856C32.021 102.073 32.2384 102.29 32.4558 102.399C32.6731 102.508 32.9992 102.616 33.3253 102.725L37.5645 100.118L38.434 101.53L32.5645 105.223L31.9123 104.354C31.8036 104.137 31.8036 104.028 31.9123 103.811L32.3471 103.377Z"
          fill="#0065F2"
        />
        <path
          d="M25.3906 90.9941H32.8906L33.4341 92.4062L27.9993 97.5112L27.4558 96.2078C27.4558 96.0991 27.3471 95.9905 27.4558 95.8819C27.4558 95.7733 27.5645 95.6647 27.5645 95.6647L30.4993 93.0579C30.7167 92.9492 30.8254 92.732 31.0428 92.6234C31.2602 92.5148 31.3689 92.4062 31.5863 92.2975C31.3689 92.2975 31.1515 92.4062 30.9341 92.4062C30.7167 92.4062 30.4993 92.4062 30.2819 92.4062L26.3689 92.5148C26.2602 92.5148 26.1515 92.5148 26.1515 92.4062C26.0428 92.4062 26.0428 92.2975 25.9341 92.1889L25.3906 90.9941Z"
          fill="#0065F2"
        />
        <path
          d="M23.8686 85.2375C23.7599 84.6944 23.7599 84.2599 23.8686 83.8255C23.9773 83.391 24.086 82.9565 24.3034 82.6307C24.5208 82.3048 24.8469 81.979 25.2817 81.7618C25.7165 81.5445 26.1512 81.3273 26.6947 81.2187C27.2382 81.1101 27.7817 81.1101 28.2165 81.2187C28.6512 81.3273 29.086 81.4359 29.4121 81.6531C29.7382 81.8704 30.0643 82.1962 30.2817 82.5221C30.4991 82.9565 30.7165 83.391 30.8252 83.8255C30.9338 84.3686 30.9338 84.803 30.8252 85.2375C30.7165 85.672 30.6078 86.1064 30.2817 86.4323C29.9556 86.7581 29.7382 87.084 29.3034 87.3012C28.8686 87.5185 28.4338 87.7357 27.8904 87.8443C27.3469 87.9529 26.8034 87.9529 26.3686 87.8443C25.9338 87.7357 25.4991 87.6271 25.173 87.4098C24.8469 87.1926 24.5208 86.8668 24.3034 86.4323C24.1947 86.2151 23.9773 85.672 23.8686 85.2375ZM29.6295 84.0427C29.5208 83.4996 29.1947 83.0652 28.7599 82.8479C28.3252 82.6307 27.7817 82.6307 27.0208 82.7393C26.2599 82.8479 25.7165 83.1738 25.3904 83.4996C25.0643 83.8255 24.9556 84.2599 25.0643 84.9116C25.173 85.4547 25.4991 85.8892 25.9338 86.1064C26.3686 86.3237 26.9121 86.3237 27.673 86.2151C28.4338 86.1064 28.9773 85.7806 29.3034 85.4547C29.6295 85.1289 29.7382 84.6944 29.6295 84.0427Z"
          fill="#0065F2"
        />
        <path
          d="M23 72.312L31.913 75.8964C32.0217 75.8964 32.1304 76.005 32.1304 76.1136C32.2391 76.2222 32.2391 76.3308 32.2391 76.4395V77.6342L29.413 76.5481L23.1087 79.3721V77.8515C23.1087 77.7429 23.1087 77.6342 23.2174 77.5256C23.3261 77.417 23.3261 77.417 23.4348 77.3084L27.0217 75.7877C27.1304 75.7877 27.2391 75.6791 27.3478 75.6791C27.4565 75.6791 27.5652 75.5705 27.6739 75.5705C27.5652 75.5705 27.4565 75.4619 27.3478 75.4619C27.2391 75.4619 27.1304 75.3533 27.0217 75.3533L23.4348 74.0499C23.3261 74.0499 23.2174 73.9413 23.2174 73.8326C23.1087 73.724 23.1087 73.6154 23.1087 73.5068L23 72.312Z"
          fill="#0065F2"
        />
        <path
          d="M27.0227 68.9448C27.3488 68.9448 27.6748 68.9448 28.0009 68.9448C28.2183 68.8362 28.5444 68.8362 28.6531 68.619C28.8705 68.5103 28.9792 68.2931 29.0879 68.0759C29.1966 67.8586 29.3053 67.6414 29.3053 67.3156C29.3053 67.0983 29.3053 66.7725 29.3053 66.6639C29.3053 66.4466 29.1966 66.2294 29.1966 66.1208C29.1966 66.0122 29.0879 65.7949 29.0879 65.6863C29.0879 65.5777 28.9792 65.4691 29.0879 65.3604C29.0879 65.2518 29.1966 65.1432 29.3053 65.0346L29.9575 64.7087C30.1748 64.926 30.2835 65.1432 30.3922 65.3604C30.5009 65.5777 30.6096 65.9035 30.6096 66.1208C30.6096 66.338 30.7183 66.6639 30.7183 66.8811C30.7183 67.0983 30.7183 67.4242 30.6096 67.6414C30.5009 68.0759 30.3922 68.5103 30.1748 68.9448C29.9575 69.3793 29.6314 69.5965 29.3053 69.9224C28.9792 70.1396 28.5444 70.3568 28.0009 70.4655C27.5662 70.5741 27.0227 70.5741 26.3705 70.4655C25.9357 70.3568 25.5009 70.2482 25.0662 70.031C24.6314 69.8137 24.3053 69.4879 24.0879 69.162C23.8705 68.8362 23.6531 68.4017 23.5444 67.9673C23.4357 67.5328 23.4357 67.0983 23.5444 66.5552C23.6531 66.1208 23.7618 65.6863 23.9792 65.3604C24.1966 65.0346 24.414 64.7087 24.7401 64.4915C25.0662 64.2743 25.3922 64.1657 25.827 64.057C26.2618 63.9484 26.6966 63.9484 27.1314 64.057C27.3488 64.057 27.5662 64.1657 27.6748 64.1657C27.7835 64.2743 27.7835 64.3829 27.7835 64.4915L27.0227 68.9448ZM26.5879 65.5777C26.3705 65.5777 26.1531 65.5777 25.9357 65.5777C25.7183 65.5777 25.6096 65.6863 25.3922 65.7949C25.1748 65.9035 25.0662 66.0122 24.9575 66.2294C24.8488 66.4466 24.7401 66.5552 24.7401 66.8811C24.6314 67.3156 24.7401 67.75 24.9575 68.0759C25.1748 68.4017 25.5009 68.619 26.0444 68.8362L26.5879 65.5777Z"
          fill="#0065F2"
        />
        <path
          d="M27.3483 55.9111C27.457 55.9111 27.5657 56.0197 27.6744 56.1283C27.7831 56.2369 27.8918 56.2369 27.8918 56.3455L30.9353 60.9075L32.0222 57.649L33.2179 58.0834L31.4788 63.0798L30.8266 62.8626C30.7179 62.8626 30.7179 62.7539 30.6092 62.7539C30.5005 62.6453 30.3918 62.6453 30.3918 62.5367L27.2396 57.9748L26.1527 61.1247L24.957 60.6902L26.6962 55.6938L27.3483 55.9111Z"
          fill="#0065F2"
        />
        <path
          d="M32.2383 49.5025L34.1948 46.5698L35.3905 47.3301L33.4339 50.2628L32.2383 49.5025Z"
          fill="#0065F2"
        />
      </svg>
      <svg
        width="120"
        height="120"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="button-svg-up svg-send-up"
      >
        <circle cx="75.5" cy="75.5" r="36.5" fill="transparent" />
        <path
          d="M53 75C52.4477 75 52 75.4477 52 76C52 76.5523 52.4477 77 53 77V75ZM97.7071 76.7071C98.0976 76.3166 98.0976 75.6834 97.7071 75.2929L91.3431 68.9289C90.9526 68.5384 90.3195 68.5384 89.9289 68.9289C89.5384 69.3195 89.5384 69.9526 89.9289 70.3431L95.5858 76L89.9289 81.6569C89.5384 82.0474 89.5384 82.6805 89.9289 83.0711C90.3195 83.4616 90.9526 83.4616 91.3431 83.0711L97.7071 76.7071ZM53 77H97V75H53V77Z"
          fill="#0065F2"
          id="btn-arrow"
        />
      </svg>
    </button>
  );
}

export default ButtonSend;
