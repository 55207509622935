import "../Slider.css";
import React from "react";
import arrow_left from "../images/arrow_left.svg";
import arrow_right from "../images/arrow_right.svg";
import portfolio from "../data/portfolio.json";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autres: this.props.autres,
      active: this.props.active,
      direction: "",
    };
    this.rightClick = this.moveRight.bind(this);
    this.leftClick = this.moveLeft.bind(this);
  }

  generateItems() {
    var items = [];
    for (var i = this.state.active; i < this.state.active + 5; i++) {
      var index = i;
      if (i < 0) {
        index = this.state.autres.length + i;
      } else if (i >= this.state.autres.length) {
        index = i % this.state.autres.length;
      }
      items.push(
        <Item
          key={index}
          id={this.state.autres[index].id}
          projects={this.state.autres[index]}
        />
      );
    }
    return items;
  }

  moveLeft() {
    var newActive = this.state.active;
    newActive--;
    this.setState({
      active: newActive < 0 ? this.state.autres.length - 1 : newActive,
      direction: "left",
    });
  }

  moveRight() {
    var newActive = this.state.active;
    this.setState({
      active: (newActive + 1) % this.state.autres.length,
      direction: "right",
    });
  }

  filterDate(e) {
    var filteredData = this.state.autres.filter((data) => {
      return data.date === e;
    });

    this.setState({
      ...this.state,
      autres: filteredData,
    });
  }

  filterAll() {
    this.setState({
      autres: this.props.autres,
    });
  }

  render() {
    return (
      <div className="carousel-container">
        <div id="arrow-container">
          <div className="arrow-carousel arrow-left" onClick={this.leftClick}>
            <img className="fi-arrow-left" src={arrow_left} alt="Arrow left" />
          </div>
          <div className="arrow-carousel arrow-right" onClick={this.rightClick}>
            <img
              className="fi-arrow-right"
              src={arrow_right}
              alt="Arrow right"
            />
          </div>
        </div>
        <div id="carousel" className="noselect">
          {/* <div className="carousel-filter">
            {Object.keys(this.state.autres)
              .map((item, index) => (
                <p key={index} onClick={() => this.filterDate(this.state.autres[item].date)}>
                  {this.state.autres[item].date}
                </p>
              ))
              .reverse()}
            <p onClick={() => this.filterAll()}>All</p>
          </div> */}
          <div className="d-flex slide">{this.generateItems()}</div>
        </div>
      </div>
    );
  }
}

function Item(props) {
  var itemStyle = {
    backgroundImage: `url(${props.projects.photo})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "80%",
    height: "80%",
    transition: "all .7s ease-out",
  };
  var containerItemStyle = {
    background: "#FFFFFF",
    boxShadow: "inset 0px 0px 25px rgb(0 101 242 / 10%)",
    height: "422.21px",
    width: "315px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <div className="item">
      <Link
        to={{
          pathname: "/project/" + props.projects.id,
          state: props.projects,
        }}
      >
        <div style={containerItemStyle} className="item-container">
          <div style={itemStyle} className="item-hover"></div>
        </div>
        <div className="item-infos">
          <ul>
            <li className="item-type">{props.projects.type}</li>
            <li className="item-id">{props.id}</li>
          </ul>
          <p className="item-name">{props.projects.name}</p>
        </div>
      </Link>
    </div>
  );
}

function Project() {
  const { t } = useTranslation();
  return (
    <div className="container-bg bg-project" id="project">
      <div className="container-title">
        <div className="sub-contain">
          <h2 className="h2-slider">{t("project.title")}</h2>
          <p>{t("project.text")}</p>
        </div>
      </div>
      <Carousel autres={portfolio.projects} active={0} />
    </div>
  );
}

export default Project;
